import { Form, Radio } from "antd";
import { useForm } from "antd/es/form/Form";
import { useCallback, useEffect } from "react";
import styled from "styled-components";

import { Button } from "../../components/Button";
import { I18N, useI18N } from "../../components/I18N";
import { Reservation, reservationState, useGNB } from "../../atom";
import bullet from "./bullet.svg";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { HospitalType } from "../../enums/hospital_type";
import { Wrapper } from "../../components/Wrapper";

type FormValues = Pick<Reservation, "hospitalType">;

export const ReservationStep4 = () => {
  const [reservation, setReservation] = useRecoilState(reservationState);

  const navigate = useNavigate();
  useEffect(() => {
    if (reservation.type === null) {
      navigate("/");
    }
  }, [navigate, reservation.type]);

  useGNB({
    title: useI18N("reservation-step4__title"),
    back: "/reservation/step3",
  });

  const [form] = useForm();
  const handleFinish = useCallback(
    (values: FormValues) => {
      setReservation((prev) => ({
        ...prev,
        hospitalType: values.hospitalType,
      }));

      navigate("/reservation/step5");
    },
    [navigate, setReservation]
  );

  useEffect(() => {
    form.setFieldsValue({
      hospitalType: reservation.hospitalType,
    });
  }, [form, reservation]);

  return (
    <Wrapper>
      <Form form={form} onFinish={handleFinish}>
        <SubTitle>
          <I18N name="reservation-step4__subtitle" />
        </SubTitle>

        <Form.Item name="hospitalType" noStyle>
          <Radio.Group name="hospitalType" style={{ width: "100%" }}>
            <Radio
              style={{ display: "block" }}
              rootClassName="antd-large"
              value={HospitalType.Hospital}
            >
              <WhiteSpan style={{ paddingRight: 5 }}>
                <I18N name="reservation-step4__type-hospital--name" />
              </WhiteSpan>
              <BulletBox>
                <Bullet>
                  <I18N name="reservation-step4__type-hospital--feature1" />
                </Bullet>
                <Bullet>
                  <I18N name="reservation-step4__type-hospital--feature2" />
                </Bullet>
                <Bullet>
                  <I18N name="reservation-step4__type-hospital--feature3" />
                </Bullet>
                <Bullet>
                  <I18N name="reservation-step4__type-hospital--feature4" />
                </Bullet>
              </BulletBox>
            </Radio>

            <Radio
              style={{ display: "block" }}
              rootClassName="antd-large"
              value={HospitalType.Clinic}
            >
              <WhiteSpan style={{ paddingRight: 5 }}>
                <I18N name="reservation-step4__type-clinic--name" />
              </WhiteSpan>
              <BulletBox>
                <Bullet>
                  <I18N name="reservation-step4__type-clinic--feature1" />
                </Bullet>
                <Bullet>
                  <I18N name="reservation-step4__type-clinic--feature2" />
                </Bullet>
                <Bullet>
                  <I18N name="reservation-step4__type-clinic--feature3" />
                </Bullet>
                <Bullet>
                  <I18N name="reservation-step4__type-clinic--feature4" />
                </Bullet>
              </BulletBox>
            </Radio>

            <Radio
              style={{ display: "block" }}
              rootClassName="antd-large"
              value={HospitalType.Any}
            >
              <WhiteSpan style={{ paddingRight: 5 }}>
                <I18N name="reservation-step4__type-any--name" />
              </WhiteSpan>
              <BulletBox></BulletBox>
            </Radio>
          </Radio.Group>
        </Form.Item>

        <div style={{ flex: 1 }} />

        <Form.Item dependencies={["hospitalType"]}>
          {({ getFieldsValue }) => {
            const values: FormValues = getFieldsValue();

            if (values.hospitalType !== undefined) {
              return (
                <Button type="submit">
                  <I18N name="reservation-step4__submit" />
                </Button>
              );
            }

            return (
              <Button type="submit" disabled>
                <I18N name="reservation-step4__submit--disabled" />
              </Button>
            );
          }}
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

const BulletBox = styled.div`
  padding-top: 16px;
  padding-left: 33px;
  display: flex;
  flex-direction: column;
`;

const Bullet = styled.div`
  background-image: url(${bullet});
  background-repeat: no-repeat;
  background-position: left top 8px;
  padding-left: 20px;
  margin-bottom: 6px;
  margin-inline-start: 0 !important;
  font-family: Noto Sans KR;
  font-weight: 300;
  font-size: 14px !important;
`;

const Pricing = styled.div`
  flex: 1;
  position: absolute;
  top: 0;
  right: 0;
  width: calc(100% - 30px);
  text-align: right;
  z-index: -1;

  &::before {
    content: "''";
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px #d2d2d2 dashed;
  }
`;

const WhiteSpan = styled.span`
  background: #ffffff;
  position: relative;
  z-index: 10;
`;

const SubTitle = styled.div`
  font-family: SCoreDream;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 24px;
`;
