import { Form } from "antd";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button } from "../../components/Button";
import { I18N, useI18N } from "../../components/I18N";
import { Reservation, reservationState, useGNB } from "../../atom";
import { useProgress } from "../../components/GNB";
import { Wrapper } from "../../components/Wrapper";

type FormValues = Pick<Reservation, "toDoctor">;

export const ReservationStep12 = () => {
  const [reservation, setReservation] = useRecoilState(reservationState);

  useGNB({
    title: useI18N("reservation-step12__title"),
    back: "/reservation/step11",
    progress: useProgress(12),
  });

  const toDoctorPlaceholder = useI18N("field__to-doctor--placeholder");

  const navigate = useNavigate();

  useEffect(() => {
    if (reservation.type === null) {
      navigate("/");
    }
  }, [navigate, reservation.type]);

  const [form] = useForm();
  const handleFinish = useCallback(
    (values?: FormValues) => {
      if (values) {
        setReservation((prev) => ({
          ...prev,
          toDoctor: values.toDoctor,
        }));
      }

      const id = "CPA230221000010";
      navigate(`/r/${id}/payment`);
    },
    [navigate, setReservation]
  );

  useEffect(() => {
    const initial = {
      toDoctor: reservation.toDoctor,
    };

    form.setFieldsValue(initial);
  }, [form, reservation]);

  return (
    <Wrapper>
      <Form form={form} onFinish={handleFinish} layout="vertical">
        <SubTitle>
          <I18N name="reservation-step12__subtitle" />
        </SubTitle>

        <Form.Item name="toDoctor">
          <TextArea
            placeholder={toDoctorPlaceholder}
            rows={6}
            style={{ resize: "none" }}
          />
        </Form.Item>

        <Bullet>
          <I18N name="reservation-step12__comment--1" />
        </Bullet>
        <Bullet>
          <I18N name="reservation-step12__comment--2" />
        </Bullet>
        <Bullet>
          <I18N name="reservation-step12__comment--3" />
        </Bullet>

        <div style={{ flex: 1 }} />

        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 50 }}
        >
          <button
            type="button"
            onClick={() => handleFinish()}
            style={{
              background: "none",
              color: "#9ea6b4",
              border: "none",
              fontSize: 13,
            }}
          >
            <I18N name="reservation-step9__skip" />
          </button>
        </div>

        <Form.Item dependencies={["toDoctor"]} style={{ marginTop: 12 }}>
          {({ getFieldsValue }) => {
            const values: FormValues = getFieldsValue();
            if (values?.toDoctor) {
              return (
                <Button type="submit">
                  <I18N name="reservation-step12__submit" />
                </Button>
              );
            }

            return (
              <Button type="submit" disabled>
                <I18N name="reservation-step12__submit--disabled" />
              </Button>
            );
          }}
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

const SubTitle = styled.div`
  font-family: SCoreDream;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 24px;
`;

const Bullet = styled.div`
  font-size: 12px;
  line-height: 20px;

  &::before {
    content: "✔️ ";
  }
`;
