import { useEffect } from "react";
import { atom, AtomEffect, useSetRecoilState } from "recoil";
import SimpleCrypto from "simple-crypto-js";
import type { Moment } from "moment-timezone";
import moment from "moment-timezone";

import { HospitalType } from "./enums/hospital_type";
import { browserLocale, Locale } from "./enums/locale";
import { ReservationType } from "./enums/reservation_type";
import { PregnancyStatus } from "./enums/pregnancy_status";

const sc = new SimpleCrypto("hitchmed*");

export const localStorageEffect =
  (key: string): AtomEffect<any> =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      try {
        const data = sc.decrypt(savedValue) as any;

        if (Array.isArray((data as any).times)) {
          data.times = data.times?.map((i: string) => moment(i));
        }

        setSelf(data);
      } catch (e) {}
    }

    onSet((newValue, _, isReset) => {
      if (isReset) {
        localStorage.removeItem(key);
      } else {
        const json = sc.encrypt(newValue);
        localStorage.setItem(key, json);
      }
    });
  };

type GNBConfig = {
  title: string | null;
  back: boolean | string;
  progress?: number;
};

export const gnbState = atom<GNBConfig>({
  default: {
    title: null,
    back: false,
  },
  key: "gnb-title",
});

export const useGNB = (config: GNBConfig) => {
  const setTitle = useSetRecoilState(gnbState);

  useEffect(() => {
    setTitle(config);
  }, [config, setTitle]);
};

export const localeState = atom<Locale>({
  default: browserLocale(),
  key: "locale",
  effects: [localStorageEffect("locale")],
});

export const sessionIndexState = atom<number>({
  default: 0,
  key: "session-index",
  effects: [localStorageEffect("session-index")],
});

export type Reservation = {
  type: ReservationType | null;
  hospitalType?: HospitalType;
  firstName?: string;
  lastName?: string;
  birthday?: string;
  gender?: "male" | "female";
  email?: string;
  arrival?: string;
  departure?: string;
  "travel-insurance"?: boolean;
  times?: Moment[];
  "request-language"?: string[];
  "accompanying-persons"?: number;
  nationality?: string;
  symptom?: string;
  pregnant?: PregnancyStatus;
  medicalHistory?: string[];
  toDoctor?: string;

  //
  _checkPregnancy?: boolean;
  _checkStep8Notice?: boolean;
};

export const reservationState = atom<Reservation>({
  default: { type: null },
  key: "reservation",
  effects: [localStorageEffect("reservation")],
});

/**
 * Medical History
 */
// page 1
export const medicalHistoryOthersCommentState = atom<string | null>({
  default: null,
  key: "history-others-comment",
  effects: [localStorageEffect("history-others-comment")],
});

// page 2
export const medicalHistoryCheckupInMonthState = atom<number | null>({
  default: null,
  key: "history-checkup-in-month",
  effects: [localStorageEffect("history-checkup-in-month")],
});

export const medicalHistoryAllergyCommentState = atom<string | null>({
  default: null,
  key: "history-allergy-comment",
  effects: [localStorageEffect("history-allergy-comment")],
});

export const medicalHistorySurgeryInMonthState = atom<number | null>({
  default: null,
  key: "history-surgery-in-month",
  effects: [localStorageEffect("history-surgery-in-month")],
});

export const medicalHistoryRadiationInMonthState = atom<number | null>({
  default: null,
  key: "history-radiation-in-month",
  effects: [localStorageEffect("history-radiation-in-month")],
});
