export enum Locale {
  EN = "en",
  JA = "ja",
  KO = "ko",
  ZH = "zh",
}

export const browserLocale = (): Locale => {
  const language = (
    navigator.language || (navigator as any).userLanguage
  ).toLowerCase();
  let locale = Locale.EN;

  Object.values(Locale).forEach((l) => {
    if (language.startsWith(l)) {
      locale = l;
    }
  });

  return locale;
};
