import { Form } from "antd";
import { useForm } from "antd/es/form/Form";
import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button } from "../../components/Button";
import { I18N, useI18N } from "../../components/I18N";
import { Reservation, reservationState, useGNB } from "../../atom";
import { ReservationDateSelector } from "../../components/ReservationDateSelector";
import { Wrapper } from "../../components/Wrapper";

type FormValues = Pick<Reservation, "times">;

export const ReservationStep5 = () => {
  const [reservation, setReservation] = useRecoilState(reservationState);
  const navigate = useNavigate();

  useEffect(() => {
    if (reservation.type === null) {
      navigate("/");
    }
  }, [navigate, reservation.type]);

  useGNB({
    title: useI18N("reservation-step5__title"),
    back: "/reservation/step4",
  });

  const [form] = useForm();
  const handleFinish = useCallback(
    (values: FormValues) => {
      setReservation((prev) => ({
        ...prev,
        times: values.times,
      }));

      navigate("/reservation/step6");
    },
    [navigate, setReservation]
  );

  useEffect(() => {
    form.setFieldsValue({
      times: reservation.times,
    });
  }, [form, reservation]);

  return (
    <Wrapper>
      <Form form={form} onFinish={handleFinish}>
        <SubTitle>
          <I18N name="reservation-step5__subtitle" />
        </SubTitle>

        <Form.Item name="times" noStyle>
          <ReservationDateSelector />
        </Form.Item>

        <div style={{ flex: 1 }} />

        <Form.Item dependencies={["times"]} style={{ marginTop: 16 }}>
          {({ getFieldsValue }) => {
            const values: FormValues = getFieldsValue();

            if (values.times?.length) {
              return (
                <Button type="submit">
                  <I18N name="reservation-step5__submit" />
                </Button>
              );
            }

            return (
              <Button type="submit" disabled>
                <I18N name="reservation-step5__submit--disabled" />
              </Button>
            );
          }}
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

const SubTitle = styled.div`
  font-family: SCoreDream;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 24px;
`;
