import { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";

import { Locale } from "../../enums/locale";
import { localeState, sessionIndexState } from "../../atom";
import { Button } from "../Button";
import { Dialog } from "../Dialog";
import { Flag } from "../Flag";
import { Space } from "../Space";
import iconLocale from "./icon.svg";

const nameMap = {
  [Locale.EN]: "English",
  [Locale.KO]: "한국어 | Korean",
  [Locale.ZH]: "汉语 | Chinese",
  [Locale.JA]: "日本語 | Japanese",
};

export const LocaleButton = () => {
  const [open, setOpen] = useState(false);
  const [locale, setLocale] = useRecoilState(localeState);

  const [sessionIndex, setSessionIndex] = useRecoilState(sessionIndexState);

  useEffect(() => {
    if (sessionIndex === 0) {
      setTimeout(() => {
        setSessionIndex(1);
      }, 1);

      setOpen(true);
    }
  }, [sessionIndex, setSessionIndex]);

  const handleClose = useCallback(() => {
    setOpen(false);

    return true;
  }, []);

  const handleSelect = useCallback(
    (locale: Locale) => {
      setLocale(locale);
      setOpen(false);
    },
    [setLocale]
  );

  return (
    <>
      <ToggleButton onClick={() => setOpen(true)}>{locale}</ToggleButton>
      <Dialog open={open} onClose={handleClose}>
        <h1>Please select a language</h1>
        {Object.values(Locale).map((i) => (
          <Button
            key={i}
            disabled={i === locale}
            buttonStyle="select"
            onClick={() => handleSelect(i)}
          >
            <Flag style={{ marginRight: 12 }} locale={i} />
            {nameMap[i]}
          </Button>
        ))}
        <Space size={40} />
        <Button onClick={handleClose}>확인</Button>
      </Dialog>
    </>
  );
};

const ToggleButton = styled.button`
  background: url(${iconLocale});
  background-position: right 10px center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  height: 56px;
  padding-right: 33px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  padding-left: 10px;
  background-color: transparent;
  color: #000000;
  border: none;
`;
