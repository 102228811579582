import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 40px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  flex: 1;

  & > * {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;
