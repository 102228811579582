import styled from "styled-components";
import { Swiper as SSwiper, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import slide1 from "./slide1.svg";
import slide2 from "./slide2.svg";
import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { I18N } from "../../../components/I18N";
import { useRecoilValue } from "recoil";
import { localeState } from "../../../atom";

export const Slider = () => {
  const locale = useRecoilValue(localeState);
  let [swiper, setSwiper] = useState<SSwiper | null>(null);

  const calculateHeight = useCallback(
    (_swiper?: SSwiper) => {
      if (_swiper) {
        swiper = _swiper;
        setSwiper(_swiper);
      }

      if (!swiper) {
        return;
      }

      try {
        const slides = swiper.slides;
        const _maxHeight = Math.max(
          ...slides.map((i) => {
            let heights: number[] = [];
            const nodes = i.children[0].children[1].children;
            for (let j = 0; j < nodes.length; j++) {
              const node = nodes[j] as HTMLDivElement;
              heights.push(node.offsetHeight);
            }

            return heights.reduce((a, b) => a + b, 0);
          })
        );

        (document.querySelector(":root")! as HTMLElement).style.setProperty(
          "--home-slider-max-height",
          `${_maxHeight}px`
        );
      } catch (e) {}
    },
    [swiper]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      calculateHeight();
    }, 100);

    return () => clearInterval(interval);
  }, [calculateHeight, swiper, locale]);

  const handleResize = useCallback(
    (_swiper: SSwiper) => {
      calculateHeight(_swiper);
    },
    [calculateHeight]
  );

  return (
    <Wrapper>
      <Swiper
        onInit={handleResize}
        onResize={handleResize}
        autoHeight={true}
        spaceBetween={50}
        slidesPerView={1}
        pagination={{
          bulletClass: "slider-pagination-bullet",
          bulletActiveClass: "slider-pagination-bullet-active",
          enabled: true,
          clickable: true,
        }}
        modules={[Pagination]}
      >
        <SwiperSlide>
          <Slide
            image={slide1}
            title="home__intro-slider1-title"
            message={<I18N name="home__intro-slider1-body" />}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Slide
            image={slide2}
            title="home__intro-slider2-title"
            message={
              <>
                <I18N name="home__intro-slider2-body" />
                <ul>
                  <li>
                    <I18N name="home__intro-slider2-body-bullet1" />
                  </li>
                  <li>
                    <I18N name="home__intro-slider2-body-bullet2" />
                  </li>
                  <li>
                    <I18N name="home__intro-slider2-body-bullet3" />
                  </li>
                </ul>
              </>
            }
          />
        </SwiperSlide>
      </Swiper>
    </Wrapper>
  );
};

const Slide: FC<{ image: string; title: string; message: ReactNode }> = ({
  image,
  title,
  message,
}) => {
  return (
    <div>
      <ImageBox>
        <Image src={image} alt={""} />
      </ImageBox>
      <Body>
        <SlideTitle>
          <I18N name={title} />
        </SlideTitle>
        <SlideMessage>{message}</SlideMessage>
      </Body>
    </div>
  );
};

const Wrapper = styled.div`
  display: block;
  flex-direction: column;
  width: 100%;
  flex: 1;
  min-height: calc(100vh - 400px);
`;

const ImageBox = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: max(calc((100vh - 350px) / 2), 160px);
  padding-bottom: 30px;
`;

const Image = styled.img``;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  min-height: max(calc((100vh - 450px) / 2), 160px);

  li {
    display: flex;
    list-style-type: none;

    &::before {
      content: "";
      display: inline-block;
      min-width: 3px;
      min-height: 3px;
      max-width: 3px;
      max-height: 3px;
      border-radius: 100%;
      background-color: #4c4e5e;
      margin-right: 5px;
      margin-top: 8px;
    }
  }
`;

const SlideTitle = styled.div`
  font-family: Noto Sans KR;
  color: #0762c8;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  padding: 0 30px;
  text-align: center;
`;

const SlideMessage = styled.div`
  font-family: Noto Sans KR;
  color: #4c4e5e;
  font-size: 12px;
  font-weight: 300;
  max-width: 500px;
  padding: 0 30px;
`;
