import { Form, Radio } from "antd";
import { useForm } from "antd/es/form/Form";
import { useCallback, useEffect } from "react";
import styled from "styled-components";

import { Button } from "../../components/Button";
import { I18N, useI18N } from "../../components/I18N";
import { Reservation, reservationState, useGNB } from "../../atom";
import bullet from "./bullet.svg";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { ReservationType } from "../../enums/reservation_type";
import { Wrapper } from "../../components/Wrapper";

type FormValues = Pick<Reservation, "type">;

export const ReservationStep1 = () => {
  const [reservation, setReservation] = useRecoilState(reservationState);

  useGNB({
    title: useI18N("reservation-step1__title"),
    back: "/precautions",
  });

  const navigate = useNavigate();
  const [form] = useForm();
  const handleFinish = useCallback(
    (values: FormValues) => {
      setReservation((prev) => ({
        ...prev,
        type: values.type,
      }));

      navigate("/reservation/step2");
    },
    [navigate, setReservation]
  );

  useEffect(() => {
    form.setFieldsValue({
      type: reservation.type,
    });
  }, [form, reservation]);

  return (
    <Wrapper id="wwwr">
      <Form form={form} onFinish={handleFinish}>
        <Form.Item name="type" noStyle>
          <Radio.Group name="type" style={{ width: "100%" }}>
            <Radio
              style={{ display: "block" }}
              rootClassName="antd-large"
              value={ReservationType.Standard}
            >
              <WhiteSpan style={{ paddingRight: 5 }}>
                <I18N name="reservation-step1__type--standard-name" />
              </WhiteSpan>
              <Pricing>
                <WhiteSpan style={{ paddingLeft: 5 }}>$ 0</WhiteSpan>
              </Pricing>
              <BulletBox>
                <Bullet>
                  <I18N name="reservation-step1__type--standard--feature1" />
                </Bullet>
                <Bullet>
                  <I18N name="reservation-step1__type--standard--feature2" />
                </Bullet>
              </BulletBox>
            </Radio>

            <Radio
              style={{ display: "block" }}
              rootClassName="antd-large"
              value={ReservationType.Premium}
            >
              <WhiteSpan style={{ paddingRight: 5 }}>
                <I18N name="reservation-step1__type--premium-name" />
              </WhiteSpan>
              <Pricing>
                <WhiteSpan style={{ paddingLeft: 5 }}>$ 300 /3hr</WhiteSpan>
              </Pricing>

              <BulletBox>
                <Bullet>
                  <I18N name="reservation-step1__type--premium--feature1" />
                </Bullet>
                <Bullet>
                  <I18N name="reservation-step1__type--premium--feature2" />
                </Bullet>
                <Bullet>
                  <I18N name="reservation-step1__type--premium--feature3" />
                </Bullet>
                <Bullet>
                  <I18N name="reservation-step1__type--premium--feature4" />
                </Bullet>
                <Bullet>
                  <I18N name="reservation-step1__type--premium--feature5" />
                  <br />
                  <I18N name="reservation-step1__type--premium--feature5-2" />
                </Bullet>
              </BulletBox>
            </Radio>
          </Radio.Group>
        </Form.Item>

        <div style={{ flex: 1 }} />

        <Form.Item dependencies={["type"]}>
          {({ getFieldsValue }) => {
            const values: FormValues = getFieldsValue();

            if (values.type !== undefined) {
              return (
                <Button type="submit">
                  <I18N name="reservation-step1__submit" />
                </Button>
              );
            }

            return (
              <Button type="submit" disabled>
                <I18N name="reservation-step1__submit--disabled" />
              </Button>
            );
          }}
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

const BulletBox = styled.div`
  padding-top: 16px;
  padding-left: 33px;
  display: flex;
  flex-direction: column;
`;

const Bullet = styled.div`
  background-image: url(${bullet});
  background-repeat: no-repeat;
  background-position: left top 8px;
  padding-left: 20px;
  margin-bottom: 6px;
  margin-inline-start: 0 !important;
  font-family: Noto Sans KR;
  font-weight: 300;
  font-size: 14px !important;
`;

const Pricing = styled.div`
  flex: 1;
  position: absolute;
  top: 0;
  right: 0;
  width: calc(100% - 30px);
  text-align: right;
  z-index: -1;

  &::before {
    content: "''";
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px #d2d2d2 dashed;
  }
`;

const WhiteSpan = styled.span`
  background: #ffffff;
  position: relative;
  z-index: 10;
`;
