import { Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import SlideDown from "react-slidedown";
import { useRecoilState } from "recoil";
import { uniq } from "lodash";
import { FC, Fragment, useCallback } from "react";
import styled from "styled-components";

import check from "./check.svg";
import checkActive from "./check--active.svg";
import { I18N, useI18N } from "../I18N";
import {
  medicalHistoryAllergyCommentState,
  medicalHistoryCheckupInMonthState,
  medicalHistoryOthersCommentState,
  medicalHistoryRadiationInMonthState,
  medicalHistorySurgeryInMonthState,
} from "../../atom";

export const MEDICAL_HISTORY_ITEMS = [
  {
    page: 1,
    label: "특별히 앓고있는 질환 없음",
    id: "no-history-1",
  },
  {
    page: 1,
    label: "고혈압",
    id: "hypertension",
  },
  {
    page: 1,
    label: "당뇨병",
    id: "diabetes",
  },
  {
    page: 1,
    label: "고지혈증",
    id: "hyperlipidemia",
  },
  {
    page: 1,
    label: "B형간염",
    id: "hepatitis-b",
  },
  {
    page: 1,
    label: "기타과거력",
    id: "manual-history",
  },
  {
    page: 2,
    label: "최근에 건강검진을 받음",
    id: "medical-check-up",
  },
  {
    page: 2,
    label: "알레르기가 있는 약물 또는 물질이 있음",
    id: "allergy",
  },
  {
    page: 2,
    label: "과거에 수술받은적 있음",
    id: "surgery",
  },
  {
    page: 2,
    label: "방사선 치료를 받은적 있음",
    id: "radiation",
  },
  {
    page: 2,
    label: "해당되는 사항없음",
    id: "no-history-2",
  },
];

export const MedicalHistorySelector: FC<{
  value?: string[];
  onChange?: { (value: string[]): unknown };
  page: 1 | 2;
}> = ({ value, onChange, page }) => {
  const [othersComment, setOthersComment] = useRecoilState(
    medicalHistoryOthersCommentState
  );
  const [checkupInMonth, setCheckupInMonth] = useRecoilState(
    medicalHistoryCheckupInMonthState
  );
  const [allergyComment, setAllergyComment] = useRecoilState(
    medicalHistoryAllergyCommentState
  );
  const [surgeryInMonth, setSurgeryInMonth] = useRecoilState(
    medicalHistorySurgeryInMonthState
  );
  const [radiationInMonth, setRadiationInMonth] = useRecoilState(
    medicalHistoryRadiationInMonthState
  );

  const medicalHistoryCommentPlaceholder = useI18N(
    "field__medical_history--others-comment--placeholder"
  );
  const medicalHistoryCheckupInMonthPlaceholder = useI18N(
    "field__medical_history--checkup-in-month--placeholder"
  );
  const medicalHistoryAllergyCommentPlaceholder = useI18N(
    "field__medical_history--allergy-comment--placeholder"
  );
  const medicalHistorySurgeryInMonthPlaceholder = useI18N(
    "field__medical_history--surgery-in-month--placeholder"
  );
  const medicalHistoryRadiationInMonthPlaceholder = useI18N(
    "field__medical_history--radiation-in-month--placeholder"
  );
  const monthSuffix = useI18N("field__medical_history--in-month-suffix");

  const handleToggle = useCallback(
    (id: string) => {
      const current: string[] =
        value?.filter((i) =>
          MEDICAL_HISTORY_ITEMS.find((j) => j.page === page && j.id === i)
        ) || [];

      const others: string[] =
        value?.filter((i) =>
          MEDICAL_HISTORY_ITEMS.find((j) => j.page !== page && j.id === i)
        ) || [];

      if (id.startsWith("no-history")) {
        onChange?.([...others, id]);
      } else if (value?.includes(id)) {
        onChange?.(
          [...others, ...current.filter((i) => i !== id)].filter(
            (i) => i !== `no-history-${page}`
          )
        );
      } else {
        onChange?.(
          uniq([...others, ...current, id]).filter(
            (i) => i !== `no-history-${page}`
          )
        );
      }
    },
    [onChange, page, value]
  );

  return (
    <Wrapper>
      {MEDICAL_HISTORY_ITEMS.filter((i) => i.page === page).map(
        ({ label, id }) => (
          <Fragment key={id}>
            <Checkbox
              checked={value?.includes(id)}
              onClick={() => handleToggle(id)}
            >
              <I18N name={`field__medical_history--page-${page}-${id}`} />
            </Checkbox>
            {id === "manual-history" && (
              <SlideDown closed={!value?.includes("manual-history")}>
                <Form>
                  <Form.Item
                    name="medical-history-comment"
                    initialValue={othersComment}
                  >
                    <TextArea
                      style={{ resize: "none" }}
                      rows={4}
                      placeholder={medicalHistoryCommentPlaceholder}
                      onBlur={(e) => setOthersComment(e.target.value)}
                    />
                  </Form.Item>
                </Form>
              </SlideDown>
            )}
            {id === "medical-check-up" && (
              <SlideDown closed={!value?.includes("medical-check-up")}>
                <Form>
                  <ItemMonth
                    name="medical-history-medical-check-up"
                    initialValue={checkupInMonth}
                    suffix={monthSuffix}
                  >
                    <Input
                      style={{ resize: "none" }}
                      placeholder={medicalHistoryCheckupInMonthPlaceholder}
                      onBlur={(e) => {
                        const value = /^\d+$/.test(e.target.value || "")
                          ? Number(e.target.value)
                          : 0;

                        setCheckupInMonth(value);
                      }}
                    />
                  </ItemMonth>
                </Form>
              </SlideDown>
            )}
            {id === "allergy" && (
              <SlideDown closed={!value?.includes("allergy")}>
                <Form>
                  <Form.Item
                    name="medical-history-allergy"
                    initialValue={allergyComment}
                  >
                    <TextArea
                      style={{ resize: "none" }}
                      rows={4}
                      placeholder={medicalHistoryAllergyCommentPlaceholder}
                      onBlur={(e) => setAllergyComment(e.target.value || null)}
                    />
                  </Form.Item>
                </Form>
              </SlideDown>
            )}
            {id === "surgery" && (
              <SlideDown closed={!value?.includes("surgery")}>
                <Form>
                  <ItemMonth
                    name="medical-history-surgery"
                    initialValue={surgeryInMonth}
                    suffix={monthSuffix}
                  >
                    <Input
                      style={{ resize: "none" }}
                      placeholder={medicalHistorySurgeryInMonthPlaceholder}
                      onBlur={(e) => {
                        const value = /^\d+$/.test(e.target.value || "")
                          ? Number(e.target.value)
                          : 0;

                        setSurgeryInMonth(value);
                      }}
                    />
                  </ItemMonth>
                </Form>
              </SlideDown>
            )}
            {id === "radiation" && (
              <SlideDown closed={!value?.includes("radiation")}>
                <Form>
                  <ItemMonth
                    name="medical-history-radiation"
                    initialValue={radiationInMonth}
                    suffix={monthSuffix}
                  >
                    <Input
                      style={{ resize: "none" }}
                      placeholder={medicalHistoryRadiationInMonthPlaceholder}
                      onBlur={(e) => {
                        const value = /^\d+$/.test(e.target.value || "")
                          ? Number(e.target.value)
                          : 0;

                        setRadiationInMonth(value);
                      }}
                    />
                  </ItemMonth>
                </Form>
              </SlideDown>
            )}
          </Fragment>
        )
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Checkbox = styled.button.attrs({ type: "button" })<{ checked?: boolean }>`
  min-height: 48px;
  margin-bottom: 4px;
  padding-left: 28px;
  text-align: left;
  font-size: 16px;
  color: ${({ checked }) => (checked ? "#000" : "#626262")};
  display: block;
  font-weight: 300;
  border: none;
  background: none;
  background-image: url(${({ checked }) => (checked ? checkActive : check)});
  background-repeat: no-repeat;
  background-position: 0 50%;
`;

const ItemMonth = styled(Form.Item)<{ suffix: string }>`
  position: relative;

  &::after {
    content: "${({ suffix }) => suffix}";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 13px;
    font-size: 16px;
  }
`;
