import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Layout } from "./pages/Layout";
import { Home } from "./pages/Home";
import { RecoilRoot } from "recoil";
import { Precautions } from "./pages/Precautions";
import { ReservationStep1 } from "./pages/ReservationStep1";
import { ReservationStep2 } from "./pages/ReservationStep2";
import { ReservationStep3 } from "./pages/ReservationStep3";
import { ReservationStep4 } from "./pages/ReservationStep4";
import { ReservationStep5 } from "./pages/ReservationStep5";
import { ReservationStep6 } from "./pages/ReservationStep6";
import { ReservationStep7 } from "./pages/ReservationStep7";
import { ReservationStep8 } from "./pages/ReservationStep8";
import { ReservationStep9 } from "./pages/ReservationStep9";
import { ReservationStep10 } from "./pages/ReservationStep10";
import { ReservationStep11 } from "./pages/ReservationStep11";
import { ReservationStep12 } from "./pages/ReservationStep12";
import { Payment } from "./pages/Payment";
import { ReservationDetail } from "./pages/ReservationDetail";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <div>Error</div>,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/precautions",
        element: <Precautions />,
      },
      {
        path: "/reservation/step1",
        element: <ReservationStep1 />,
      },
      {
        path: "/reservation/step2",
        element: <ReservationStep2 />,
      },
      {
        path: "/reservation/step3",
        element: <ReservationStep3 />,
      },
      {
        path: "/reservation/step4",
        element: <ReservationStep4 />,
      },
      {
        path: "/reservation/step5",
        element: <ReservationStep5 />,
      },
      {
        path: "/reservation/step6",
        element: <ReservationStep6 />,
      },
      {
        path: "/reservation/step7",
        element: <ReservationStep7 />,
      },
      {
        path: "/reservation/step8",
        element: <ReservationStep8 />,
      },
      {
        path: "/reservation/step9",
        element: <ReservationStep9 />,
      },
      {
        path: "/reservation/step10",
        element: <ReservationStep10 />,
      },
      {
        path: "/reservation/step11",
        element: <ReservationStep11 />,
      },
      {
        path: "/reservation/step12",
        element: <ReservationStep12 />,
      },
      {
        path: "/r/:id/payment",
        element: <Payment />,
      },
      {
        path: "/r/:id",
        element: <ReservationDetail />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <RouterProvider router={router} />
    </RecoilRoot>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
