import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";

import { Footer } from "../components/Footer";
import { GNB } from "../components/GNB";

export const Layout = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Wrapper>
        <div id="container">
          <GNB />
          <div className="body">
            <Outlet />
          </div>
        </div>
      </Wrapper>
      <Footer />

      <style>
        {`
.antd-large .ant-radio-inner {
  width: 20px !important;
  height: 20px !important;
  transform: translateY(3px) !important;
}

.ant-radio-inner {
  border-radius: 2px !important;
  background-color: #DEDEDE !important;
  background-image: url(/assets/icon__checkbox.svg) !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.ant-radio-wrapper .ant-radio-inner::after {
  background-color: #0762C8 !important;
  width: 100% !important;
  height: 100% !important;
  transform: scale(1) !important;
  background-image: url(/assets/icon__checkbox.svg) !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  border-radius: 0 !important;
  inset-block-start: 0 !important;
  inset-inline-start: 0 !important;
  margin-block-start: 0 !important;
  margin-inline-start:0 !important;
  border-block-start: 0 !important;
  border-inline-start: 0 !important;
}

.ant-radio-wrapper {
  color: #242424;
  font-size: 16px !important;
  font-weight: 300;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-radio-wrapper-checked {
  font-weight: 500;
}

/* form item */
.ant-form-item-control-input {}
.ant-form-item-control-input + div {
  display: none !important;
}

.ant-form-item-margin-offset {
  display: none;
}

.ant-form-item-required::before {
  display: none !important;
}

.ant-form-item-label{
  padding-bottom: 3px !important;
}

.ant-form-item-label label {
  font-family: Noto Sans KR;
  color: #4f4f4f;
  font-size: 12px !important;
}

.ant-input {
  font-family: Noto Sans KR;
  background-color: #F8F8F8;
  border: none !important;
  height: 40px;
  font-size: 16px;
}

.ant-input::placeholder {
  color: #A6A6A6;
}

.ant-select-selector {
  height: 40px !important;
  background-color: #F8F8F8 !important;
  border: none !important;
  font-size: 16px !important;
}

.ant-select-selection-item {
  line-height: 38px !important;
}

`}
      </style>
    </>
  );
};

const Wrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
`;
