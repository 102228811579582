import styled from "styled-components";
import logo from "./logo.svg";

export const Footer = () => {
  return (
    <Wrapper>
      <Company>
        주식회사 히치메드 <Small>(HitchMED Co., Ltd.)</Small>
      </Company>
      <Body>
        사업자등록번호 : 614-86-01229 | 대표 : 유지상, 박소현
        <br />
        통신판매업 번호 : 2022-성남분당C-0726
        <br />
        본사 : 경기도 성남시 분당구 성남대로331번길 8, 20층 11호실(정자동,
        킨스타워)
        <br />
        연구소 : 서울시 성북구 종암로 18 거산프라자 신관 405호
        <br />
        대표번호 02-943-9403
      </Body>

      <img src={logo} alt="HitchMED Co., Ltd." />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #26313d;
  padding: 30px 15px 20px 15px;
  text-align: center;
`;

const Company = styled.div`
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 11px;
`;

const Small = styled.span`
  font-size: 10px;
`;

const Body = styled.div`
  font-size: 10px;
  font-weight: 300;
  color: #ccc;
  text-align: center;
  margin-bottom: 24px;
`;
