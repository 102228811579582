import { Form } from "antd";
import { useForm } from "antd/es/form/Form";
import { sortBy } from "lodash";
import moment, { Moment } from "moment-timezone";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import { localeState, reservationState } from "../../atom";
import { Button } from "../Button";
import { I18N, useI18N } from "../I18N";
import { ReservationCalendar } from "../ReservationCalendar";
import bullet from "./bullet.svg";
import check from "./check.svg";
import remove from "./remove.svg";

moment.tz.setDefault("Asia/Seoul");

export const ReservationDateSelector: FC<{
  value?: Moment[];
  onChange?: { (date: Moment[]): unknown };
}> = ({ value, onChange }) => {
  const { arrival, departure } = useRecoilValue(reservationState);

  const { min, max } = useMemo(() => {
    const min = moment
      .max(moment(arrival).add(1, "day"), moment().add(1, "day"))
      .startOf("day");
    const max = moment(departure).endOf("day");

    return {
      min,
      max,
    };
  }, [arrival, departure]);
  const locale = useRecoilValue(localeState);

  const dateFormat = useI18N("reservation-date-selector__date-format");
  const am = useI18N("reservation-date-selector__am");
  const pm = useI18N("reservation-date-selector__pm");

  const [form] = useForm();

  const handleUpdate = useCallback(
    (date: Moment, aaa: "am" | "pm") => {
      if (!onChange) {
        return;
      }

      onChange(
        sortBy(
          [
            ...(value || []).filter((i) => !i.isSame(date)),
            date.clone().hour(aaa === "am" ? 9 : 13),
          ],
          (a) => a.toDate().getTime()
        )
      );

      form.setFieldsValue({
        selected: undefined,
      });
    },
    [form, onChange, value]
  );

  useEffect(() => {
    moment.locale(locale, {
      meridiem: (hour) => (hour < 12 ? am : pm),
    });
  }, [am, locale, pm]);

  const handleRemove = useCallback(
    (date: Moment) => {
      if (!value || !onChange) {
        return;
      }

      onChange(value.filter((i) => !i.isSame(date)));
    },
    [onChange, value]
  );

  return (
    <Form form={form}>
      <Form.Item name="selected" noStyle>
        <ReservationCalendar min={min} max={max} marks={value || []} />
      </Form.Item>

      <Form.Item dependencies={["selected"]} noStyle>
        {({ getFieldValue }) => {
          const selected = getFieldValue("selected");

          if (!selected) {
            return null;
          }

          return (
            <div style={{ marginTop: 10 }}>
              <div
                style={{
                  color: "#0762C8",
                  fontSize: "12px",
                  fontWeight: 500,
                  marginBottom: 6,
                }}
              >
                {selected?.format(dateFormat)}
              </div>
              <div style={{ display: "flex", marginBottom: 20 }}>
                <Button2
                  disabled={
                    (value?.length || 0) >= 5 || selected.isSame(min, "date")
                  }
                  checked={
                    !!value?.find(
                      (i) => i.isSame(selected, "date") && i.get("hour") < 12
                    )
                  }
                  type="button"
                  style={{ margin: 0, maxWidth: 120, marginRight: 6 }}
                  onClick={() => handleUpdate(selected, "am")}
                >
                  {am}
                </Button2>
                <Button2
                  disabled={
                    (value?.length || 0) >= 5 || selected.isSame(max, "date")
                  }
                  checked={
                    !!value?.find(
                      (i) => i.isSame(selected, "date") && i.get("hour") >= 12
                    )
                  }
                  type="button"
                  style={{ margin: 0, maxWidth: 120 }}
                  onClick={() => handleUpdate(selected, "pm")}
                >
                  {pm}
                </Button2>
              </div>
            </div>
          );
        }}
      </Form.Item>

      <div
        style={{
          paddingLeft: 20,
          paddingBottom: 20,
          marginBottom: 20,
          borderBottom: "1px #ECECEC solid",
          marginLeft: -20,
          marginRight: -20,
        }}
      >
        <Bullet>
          <I18N name="reservation-date-selector__note-1" />
        </Bullet>
        <Bullet>
          <I18N name="reservation-date-selector__note-2" />
        </Bullet>
      </div>

      <div>
        <div style={{ marginBottom: 7, color: "#4f4f4f", fontSize: "12px" }}>
          <I18N
            name={
              (value?.length || 1) >= 2
                ? "reservation-date-selector__selected-times"
                : "reservation-date-selector__selected-time"
            }
          />
        </div>
        {!value?.length && (
          <SelectedMark
            style={{
              backgroundImage: "none",
              paddingLeft: 12,
              color: "#9F9F9F",
            }}
          >
            <I18N name="reservation-date-selector__no-selection" />
          </SelectedMark>
        )}
        {value?.map((i) => (
          <SelectedMark key={i.format("MM/DD/YYYY ")}>
            <span>
              {i.format(dateFormat)}{" "}
              {i.get("hour") < 12 ? (
                <I18N name="reservation-date-selector__am-note" />
              ) : (
                <I18N name="reservation-date-selector__pm-note" />
              )}
            </span>
            <RemoveMarkButton type="button" onClick={() => handleRemove(i)} />
          </SelectedMark>
        ))}
      </div>
    </Form>
  );
};

const Button2 = styled(Button)<{ checked?: boolean }>`
  background-image: url(${check});
  background-repeat: no-repeat;
  background-position: left 13px center;
  min-height: 40px;
  ${({ checked }) =>
    !checked &&
    `
      border: 1px #dbdbdb solid;
      background-color: #ffffff;
      color: #000000;
      font-weight: normal;
    `}

  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.5;
      color: #aaa;
    `}
`;

const Bullet = styled.div`
  color: #8a8a8a;
  font-size: 12px;
  padding-left: 20px;
`;

const SelectedMark = styled.div`
  background: url(${bullet});
  background-color: #f6f6f7;
  background-repeat: no-repeat;
  background-position: left 9px center;
  padding-left: 30px;
  font-size: 14px;
  font-weight: 500;
  color: #0762c8;
  min-height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  border-radius: 4px;
  justify-content: space-between;
`;

const RemoveMarkButton = styled.button`
  background: url(${remove});
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  width: 40px;
  height: 40px;
`;
