import { FC } from "react";
import styled from "styled-components";

export const Logo: FC<{ url: string }> = ({ url }) => {
  return <Wrapper src={url} />;
};

const Wrapper = styled.img`
  width: 100%;
  height: 80px;
  object-fit: contain;
`;
