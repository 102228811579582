import moment from "moment-timezone";

import { REGEX_MMDDYYYY, REGEX_YYYYMMDD } from "../constants";

moment.tz.setDefault("Asia/Seoul");

export const parseDate = (value: string) => {
  const v = value.replace(/[^0-9]/g, "");

  let date = null;
  if (REGEX_MMDDYYYY.test(v)) {
    date = moment(v.replace(REGEX_MMDDYYYY, "$3-$1-$2"));
  } else if (REGEX_YYYYMMDD.test(v)) {
    date = moment(v.replace(REGEX_YYYYMMDD, "$1-$2-$3"));
  }

  return date;
};
