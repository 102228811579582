import { Form } from "antd";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button } from "../../components/Button";
import { I18N, useI18N } from "../../components/I18N";
import { Reservation, reservationState, useGNB } from "../../atom";
import { Wrapper } from "../../components/Wrapper";

type FormValues = Pick<Reservation, "symptom">;

export const ReservationStep7 = () => {
  const [reservation, setReservation] = useRecoilState(reservationState);

  useGNB({
    title: useI18N("reservation-step7__title"),
    back: "/reservation/step6",
  });

  const symptomPlaceholder = useI18N("field__symptom--placeholder");

  const navigate = useNavigate();

  useEffect(() => {
    if (reservation.type === null) {
      navigate("/");
    }
  }, [navigate, reservation.type]);

  const [form] = useForm();
  const handleFinish = useCallback(
    (values: FormValues) => {
      setReservation((prev) => ({
        ...prev,
        symptom: values.symptom,
      }));

      navigate("/reservation/step8");
    },
    [navigate, setReservation]
  );

  useEffect(() => {
    const initial = {
      symptom: reservation.symptom,
    };

    form.setFieldsValue(initial);
  }, [form, reservation]);

  return (
    <Wrapper>
      <Form form={form} onFinish={handleFinish} layout="vertical">
        <SubTitle>
          <I18N name="reservation-step7__subtitle" />
        </SubTitle>

        <Form.Item name="symptom">
          <TextArea
            placeholder={symptomPlaceholder}
            rows={6}
            style={{ resize: "none" }}
          />
        </Form.Item>

        <Bullet>
          <I18N name="reservation-step7__comment--1" />
        </Bullet>
        <Bullet>
          <I18N name="reservation-step7__comment--2" />
        </Bullet>

        <div style={{ flex: 1 }} />

        <Form.Item dependencies={["symptom"]} style={{ marginTop: 50 }}>
          {({ getFieldsValue }) => {
            const values: FormValues = getFieldsValue();
            if (values?.symptom) {
              return (
                <Button type="submit">
                  <I18N name="reservation-step7__submit" />
                </Button>
              );
            }

            return (
              <Button type="submit" disabled>
                <I18N name="reservation-step7__submit--disabled" />
              </Button>
            );
          }}
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

const SubTitle = styled.div`
  font-family: SCoreDream;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 24px;
`;

const Bullet = styled.div`
  font-size: 12px;
  line-height: 20px;

  &::before {
    content: "✔️ ";
  }
`;
