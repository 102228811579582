import { useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

import { reservationState, useGNB } from "../../atom";
import { Wrapper } from "../../components/Wrapper";
import { Button } from "../../components/Button";
import { ReservationType } from "../../enums/reservation_type";
import iconUser from "./icon--user.svg";
import iconSymptom from "./icon--symptom.svg";
import iconCalendar from "./icon--calendar.svg";
import iconCheck from "./icon--check.svg";
import iconInterpreter from "./icon--interpreter.svg";
import { useCopyToClipboard } from "../../hooks/clipboard";
import { useCallback, useEffect, useMemo } from "react";
import { I18N, useI18N } from "../../components/I18N";
import moment from "moment-timezone";

// moment.tz.setDefault("Asia/Seoul");

export const ReservationDetail = () => {
  const [, copy] = useCopyToClipboard();
  const reservation = useRecoilValue(reservationState);
  const { id } = useParams<{ id: string }>();

  const reserveAtFormatAM = useI18N("field__reserve-at-format--am");
  const reserveAtFormatPM = useI18N("field__reserve-at-format--pm");
  const birthdayFormat = useI18N("field__birthday-format");
  const reserveAt = useMemo(() => {
    const t = moment("2023-03-28 13:00:00");
    return t.format(
      t.get("hours") >= 12 ? reserveAtFormatPM : reserveAtFormatAM
    );
  }, [reserveAtFormatAM, reserveAtFormatPM]);

  const navigate = useNavigate();

  useGNB({
    title: useI18N("reservation-detail__title--requested"),
    back: "/",
  });

  useEffect(() => {
    if (!id) {
      navigate("/");
    }
  }, [id, navigate]);

  const handleCopy = useCallback(() => {
    if (id) {
      copy(id);
      message.success("Copied!");
    }
  }, [copy, id]);

  if (!id) {
    return <></>;
  }

  return (
    <Wrapper>
      <div>
        <Title>
          <I18N name="reservation-detail__subtitle--requested" />
        </Title>
        <BulletBox>
          <div>
            <I18N name="reservation-detail__comment1--requested" />
          </div>
          <div>
            <I18N name="reservation-detail__comment2--requested" />
          </div>
          <div>
            <I18N name="reservation-detail__comment3--requested" />
          </div>
        </BulletBox>

        <ReservationIdBox onClick={handleCopy}>
          <Label>
            <I18N name="reservation-detail__id" />
          </Label>
          <ReservationId>{id}</ReservationId>
          <Copy>
            <I18N name="reservation-detail__copy-button" />
          </Copy>
        </ReservationIdBox>

        <Section extra={reserveAt}>
          <SectionTitle icon={iconUser}>
            <I18N name="reservation-detail__user-info" />
          </SectionTitle>
          <SectionItem>
            <SectionItemLabel>
              <I18N name="field__name" />
            </SectionItemLabel>
            {reservation.firstName} {reservation.lastName}
          </SectionItem>
          <SectionItem>
            <SectionItemLabel>
              <I18N name="field__birthday" />
            </SectionItemLabel>
            {moment(reservation.birthday).format(birthdayFormat)}
          </SectionItem>
          <SectionItem>
            <SectionItemLabel>
              <I18N name="field__nationality" />
            </SectionItemLabel>
            <I18N name={`country--${reservation.nationality}`} />
          </SectionItem>
          <SectionItem>
            <SectionItemLabel>
              <I18N name="field__gender" />
            </SectionItemLabel>
            <I18N name={`field__gender--${reservation.gender}`} />
          </SectionItem>
          <SectionItem>
            <SectionItemLabel>
              <I18N name="field__email" />
            </SectionItemLabel>
            {reservation.email}
          </SectionItem>
          <SectionItem>
            <SectionItemLabel>
              <I18N name="field__arrival" />
            </SectionItemLabel>
            {reservation.arrival}
          </SectionItem>
          <SectionItem>
            <SectionItemLabel>
              <I18N name="field__departure" />
            </SectionItemLabel>
            {reservation.departure}
          </SectionItem>
        </Section>
        <Section>
          <SectionTitle icon={iconSymptom}>
            <I18N name="field__major-symptoms" />
          </SectionTitle>
          <SectionItem>
            <div style={{ flexBasis: 20 }} />
            설사/복통
          </SectionItem>
        </Section>
        <Section>
          <SectionTitle icon={iconCalendar}>
            <I18N name="field__treatment-requests" />
          </SectionTitle>
          <SectionItem>
            <SectionItemLabel>
              <I18N name="field__available-treatment-days" />
            </SectionItemLabel>
            -
          </SectionItem>
          <SectionItem>
            <SectionItemLabel>
              <I18N name="field__desired-hospital-size" />
            </SectionItemLabel>

            <I18N
              name={`field__hospital-type-${reservation.hospitalType}--name`}
            />
          </SectionItem>
          <SectionItem>
            <SectionItemLabel>
              <I18N name="field__special-notes" />
            </SectionItemLabel>
            -
          </SectionItem>
        </Section>

        {reservation.type === ReservationType.Premium && (
          <>
            <Section>
              <SectionTitle icon={iconInterpreter}>
                <I18N name="field__medical-interpretation-escort-service" />
              </SectionTitle>
              <SectionItem>
                <SectionItemLabel>
                  <I18N name="field__accompanying-persons" />
                </SectionItemLabel>
                <I18N
                  name={`accompanying-persons--${reservation["accompanying-persons"]}`}
                />
              </SectionItem>
              <SectionItem>
                <SectionItemLabel>
                  <I18N name="field__interpretation-language" />
                </SectionItemLabel>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: 40,
                    width: "100%",
                  }}
                >
                  {reservation["request-language"]?.map((language, i) => (
                    <SectionItem key={language}>
                      <SectionItemLabel>
                        <I18N name={`language-selector__rank--${i + 1}`} />
                      </SectionItemLabel>
                      <I18N name={`language--${language}`} />
                    </SectionItem>
                  ))}
                </div>
              </SectionItem>
            </Section>

            <div
              style={{
                backgroundColor: "#F7F7F7",
                padding: 20,
                borderRadius: 6,
              }}
            >
              <BulletBox style={{ color: "#434343", whiteSpace: "pre-line" }}>
                <I18N name="field__notice-and-refund-guide" />
              </BulletBox>
            </div>
          </>
        )}

        <Section>
          <SectionTitle icon={iconCheck}>
            <I18N name="field__common-medical-examination-content" />
          </SectionTitle>
          {reservation.gender === "female" ? (
            <SectionItem>
              <SectionItemLabel>
                <I18N name="field__pregnancy" />
              </SectionItemLabel>
              {reservation.pregnant}
            </SectionItem>
          ) : null}
          <SectionItem>
            <SectionItemLabel>
              <I18N name="field__past-medical-history" />
            </SectionItemLabel>
            -
          </SectionItem>
          <SectionItem>
            <SectionItemLabel>
              <I18N name="field__surgical-history" />
            </SectionItemLabel>
            -
          </SectionItem>
          <SectionItem>
            <SectionItemLabel>
              <I18N name="field__health-checkup-status" />
            </SectionItemLabel>
            -
          </SectionItem>
          <SectionItem>
            <SectionItemLabel>
              <I18N name="field__radiation-therapy-history" />
            </SectionItemLabel>
            -
          </SectionItem>
          <SectionItem>
            <SectionItemLabel>
              <I18N name="field__medication-usage" />
            </SectionItemLabel>
            -
          </SectionItem>
          <SectionItem>
            <SectionItemLabel>
              <I18N name="field__allergy-history" />
            </SectionItemLabel>
            -
          </SectionItem>
        </Section>

        <Button>예약취소</Button>
      </div>
    </Wrapper>
  );
};

const Title = styled.div`
  margin-bottom: 18px;
  font-size: 20px;
  letter-spacing: -0.5px;
`;

const BulletBox = styled.div`
  font-size: 12px;
  color: #616161;
  font-weight: 300;
  margin-bottom: 15px;
`;

const Section = styled.div<{
  icon?: string;
  label?: string;
  extra?: string;
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 46px;

  &::after {
    content: "${({ extra }) => extra}";
    position: absolute;
    right: 5px;
    top: 2px;
    color: #959494;
    font-size: 11px;
    padding-top: 8px;
  }
`;

const SectionTitle = styled.div<{ icon: string }>`
  background-image: url(${({ icon }) => icon});
  background-repeat: no-repeat;
  background-position: left top 14px;
  border-bottom: 1px #c8cdd2 solid;
  padding-left: 20px;
  font-size: 16px;
  height: 38px;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;

const SectionItem = styled.div`
  height: 36px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #171717;
`;

const SectionItemLabel = styled.div`
  height: 36px;
  padding-left: 20px;
  flex-basis: 100px;
  font-size: 12px;
  color: #606060;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-right: 10px;
`;

const ReservationIdBox = styled.button`
  position: relative;
  border: none;
  background: none;
  margin-bottom: 40px;
`;

const Label = styled.div`
  font-size: 12px;
  font-weight: normal;
  text-align: left;
  margin-bottom: 6px;
`;

const ReservationId = styled.div`
  width: 100%;
  height: 46px;
  border-radius: 4px;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  color: #0762c8;
  font-size: 14px;
  font-weight: 500;
  padding: 12px;
`;

const Copy = styled.div`
  position: absolute;
  right: 12px;
  bottom: 0;
  height: 46px;
  font-size: 12px;
  font-weight: 300;
  color: #7e7e7e;
  display: flex;
  align-items: center;
  text-decoration: underline;
`;
