import styled from "styled-components";

import iconEN from "./icons/en.svg";
import iconJA from "./icons/ja.svg";
import iconKO from "./icons/ko.svg";
import iconZH from "./icons/zh.svg";

const iconMap: { [id: string]: string } = {
  en: iconEN,
  ja: iconJA,
  ko: iconKO,
  zh: iconZH,
};

export const Flag = styled.div<{ locale: string }>`
  width: 35px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background: ${({ locale: country }) => `url(${iconMap[country]})`};
`;
