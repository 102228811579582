import { FC } from "react";
import styled from "styled-components";

import address from "./address.svg";

export const Hotel: FC<{
  thumbnail: string;
  name: string;
  address: string;
}> = ({ thumbnail, name, address }) => {
  return (
    <Wrapper>
      <img src={thumbnail} width={163} alt="hotel" />
      <Name>{name}</Name>
      <Address>{address}</Address>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-radius: 4px;
  width: 100%;
  padding: 0 14px 17px 14px;
  background-color: #f8f8f8;
`;

const Name = styled.div`
  font-size: 16px;
  margin-bottom: 5px;
`;

const Address = styled.div`
  font-size: 12px;
  font-weight: 300;

  background-image: url(${address});
  background-repeat: no-repeat;
  background-position: left top 1px;
  padding-left: 17px;
`;
