import { Select } from "antd";
import { FC, useCallback, useMemo } from "react";
import styled from "styled-components";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";

import { TRANSLATABLE_LANGUAGE } from "../../constants";
import { Button } from "../Button";
import { I18N } from "../I18N";
import add from "./add.svg";
import remove from "./remove.svg";

export const LanguageSelector: FC<{
  value?: (string | null)[];
  onChange?: { (value: (string | null)[]): unknown };
  defaultOpen?: boolean;
}> = ({ value, onChange, defaultOpen }) => {
  const handleAdd = useCallback(() => {
    onChange?.([...(value || []), null]);
  }, [onChange, value]);

  const handleUpdate = useCallback(
    (v: string, k: number) => {
      onChange?.(
        (value || [null]).map((i, l) => {
          if (l === k) {
            return v;
          }
          return i;
        })
      );
    },
    [onChange, value]
  );

  const availTranslatableLanguage = useMemo(() => {
    return TRANSLATABLE_LANGUAGE.filter((i) => {
      if (value?.includes(i)) {
        return false;
      }
      return true;
    });
  }, [value]);

  return (
    <table border={0} width="100%">
      <tbody>
        <SlideDown
          className={"my-dropdown-slidedown"}
          transitionOnAppear={false}
        >
          {value?.map((i, k) => (
            <tr key={k}>
              <td>
                <Label>
                  <I18N name={`language-selector__rank--${k + 1}`} />
                </Label>
              </td>
              <td
                style={{
                  width: "100%",
                  paddingRight: 12,
                  paddingBottom: 3.5,
                  paddingTop: 3.5,
                }}
              >
                <Select
                  value={i}
                  onChange={(v) => handleUpdate(v, k)}
                  defaultOpen={defaultOpen && k === 0}
                >
                  <Select.Option disabled>
                    <I18N name="language-selector__placeholder" />
                  </Select.Option>

                  {[i, ...availTranslatableLanguage]
                    .filter((l) => l)
                    .map((j, o) => (
                      <Select.Option key={j || o} value={j}>
                        <I18N name={`language--${j}`} />
                      </Select.Option>
                    ))}
                </Select>
              </td>
              <td style={{ verticalAlign: "middle" }}>
                {k === 0 ? (
                  <Button
                    type="button"
                    onClick={handleAdd}
                    style={{
                      background: "#fff",
                      color: "#0762C8",
                      padding: "0 8px",
                      border: "1px solid #0762C8",
                      minHeight: 38,
                      height: 38,
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "normal",

                      borderRadius: 3,
                      ...(value.length >= 4
                        ? {
                            pointerEvents: "none",
                            opacity: 0.5,
                          }
                        : {}),
                    }}
                  >
                    <img src={add} alt="Add language" />
                    <I18N name="language-selector__add" />
                  </Button>
                ) : (
                  <Remove
                    type="button"
                    onClick={() =>
                      onChange?.((value || [])?.filter((_, l) => l !== k))
                    }
                  />
                )}
              </td>
            </tr>
          ))}
        </SlideDown>
      </tbody>
    </table>
  );
};

const Label = styled.div`
  font-size: 12px;
  color: #4f4f4f;
  white-space: nowrap;
  margin-right: 10px;
`;

const Remove = styled.button`
  width: 30px;
  height: 30px;
  background: url(${remove});
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  margin: 5px 0;
`;
