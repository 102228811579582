import { Form } from "antd";
import { useForm } from "antd/es/form/Form";
import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button } from "../../components/Button";
import { I18N, useI18N } from "../../components/I18N";
import { Reservation, reservationState, useGNB } from "../../atom";

import { MedicalHistorySelector } from "../../components/MedicalHistorySelector";
import { useProgress } from "../../components/GNB";
import { Wrapper } from "../../components/Wrapper";

type FormValues = Pick<Reservation, "medicalHistory">;

export const ReservationStep11 = () => {
  const [reservation, setReservation] = useRecoilState(reservationState);

  useGNB({
    title: useI18N("reservation-step11__title"),
    back: "/reservation/step10",
    progress: useProgress(11),
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (reservation.type === null) {
      navigate("/");
    }
  }, [navigate, reservation.type]);

  const [form] = useForm();
  const handleFinish = useCallback(
    (values?: FormValues) => {
      if (values) {
        setReservation((prev) => ({
          ...prev,
          medicalHistory: values.medicalHistory,
        }));
      }

      navigate("/reservation/step12");
    },
    [navigate, setReservation]
  );

  useEffect(() => {
    const initial = {
      medicalHistory: reservation.medicalHistory,
    };

    form.setFieldsValue(initial);
  }, [form, reservation]);

  return (
    <Wrapper>
      <Form form={form} onFinish={handleFinish} layout="vertical">
        <SubTitle>
          <I18N name="reservation-step11__subtitle" />
        </SubTitle>
        <SubTitle2>
          <I18N name="reservation-step11__subtitle2" />
        </SubTitle2>

        <Form.Item name="medicalHistory">
          <MedicalHistorySelector page={2} />
        </Form.Item>

        <div style={{ flex: 1 }} />

        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 50 }}
        >
          <button
            type="button"
            onClick={() => handleFinish()}
            style={{
              background: "none",
              color: "#9ea6b4",
              border: "none",
              fontSize: 13,
            }}
          >
            <I18N name="reservation-step11__skip" />
          </button>
        </div>

        <Form.Item dependencies={["medicalHistory"]} style={{ marginTop: 12 }}>
          {({ getFieldsValue }) => {
            const values: FormValues = getFieldsValue();
            if (values?.medicalHistory) {
              return (
                <Button type="submit">
                  <I18N name="reservation-step11__submit" />
                </Button>
              );
            }

            return (
              <Button type="submit" disabled>
                <I18N name="reservation-step11__submit--disabled" />
              </Button>
            );
          }}
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

const SubTitle = styled.div`
  font-family: SCoreDream;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 2px;
`;

const SubTitle2 = styled.div`
  font-family: SCoreDream;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 24px;
  color: #7e7e7e;
`;
