import { Form } from "antd";
import { useForm } from "antd/es/form/Form";
import { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button } from "../../components/Button";
import { I18N } from "../../components/I18N";
import { reservationState, useGNB } from "../../atom";
import banner from "./banner.svg";
import info from "./info.svg";
import { Dialog } from "../../components/Dialog";
import { Wrapper } from "../../components/Wrapper";

export const ReservationStep8 = () => {
  const [reservation, setReservation] = useRecoilState(reservationState);

  useGNB({
    title: "",
    back: "/reservation/step7",
  });

  const navigate = useNavigate();
  const [openNoticeDialog, setOpenNoticeDialog] = useState(false);

  const handleCloseNotice = useCallback(() => {
    setOpenNoticeDialog(false);
    return true;
  }, []);

  useEffect(() => {
    if (reservation.type === null) {
      navigate("/");
    }
  }, [navigate, reservation.type]);

  const [form] = useForm();
  const handleFinish = useCallback(() => {
    if (reservation._checkPregnancy) {
      navigate("/reservation/step9");
    } else {
      navigate("/reservation/step10");
    }
  }, [navigate, reservation._checkPregnancy]);

  useEffect(() => {
    if (!reservation._checkStep8Notice) {
      setOpenNoticeDialog(true);
      setReservation((prev) => ({
        ...prev,
        _checkStep8Notice: true,
      }));
    }
  }, [reservation._checkStep8Notice, setReservation]);

  useEffect(() => {
    const initial = {
      symptom: reservation.symptom,
    };

    form.setFieldsValue(initial);
  }, [form, reservation]);

  return (
    <Wrapper>
      <Form form={form} onFinish={handleFinish} layout="vertical">
        <img
          src={banner}
          alt="Banner"
          style={{ width: "100%", marginBottom: 28 }}
        />
        <SubTitle>
          <I18N name="reservation-step8__subtitle1" />
        </SubTitle>
        <SubTitle>
          <I18N name="reservation-step8__subtitle2" />
        </SubTitle>

        <div style={{ flex: 1 }} />

        <Bullet>
          <I18N name="reservation-step8__comment" />
        </Bullet>

        <div style={{ paddingBottom: 24 }}>
          <Button type="submit">
            <I18N name="reservation-step8__submit" />
          </Button>
        </div>
      </Form>

      <Dialog open={openNoticeDialog} onClose={handleCloseNotice}>
        <h1>
          <I18N name="reservation-step8__notice--title" />
        </h1>
        <div
          style={{
            fontSize: "14px",
            fontWeight: 300,
            marginBottom: 60,
            whiteSpace: "pre-line",
            lineHeight: "140%",
            padding: "0 3px",
          }}
        >
          <I18N name="reservation-step8__notice--body" />
        </div>
        <Button type="button" onClick={handleCloseNotice}>
          <I18N name="reservation-step8__notice--confirm" />
        </Button>
      </Dialog>
    </Wrapper>
  );
};

const SubTitle = styled.div`
  font-family: SCoreDream;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 12px;
`;

const Bullet = styled.div`
  font-size: 12px;
  line-height: 20px;
  padding-left: 18px;
  position: relative;
  font-size: 13px;
  font-weight: 300;
  margin-top: 16px;
  margin-bottom: 22px;

  &::before {
    position: absolute;
    left: 0;
    top: 3px;
    content: "";
    background: url(${info});
    background-repeat: no-repeat;
    width: 18px;
    height: 14px;
    display: inline-block;
  }
`;
