import { Form, Radio } from "antd";
import { useForm } from "antd/es/form/Form";
import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button } from "../../components/Button";
import { I18N, useI18N } from "../../components/I18N";
import { Reservation, reservationState, useGNB } from "../../atom";
import { PregnancyStatus } from "../../enums/pregnancy_status";

import notPregnant from "./not-pregnant.svg";
import notPregnantActive from "./not-pregnant--active.svg";
import pregnant from "./pregnant.svg";
import notSure from "./not-sure.svg";
import pregnantActive from "./pregnant--active.svg";
import notSureActive from "./not-sure--active.svg";
import { useProgress } from "../../components/GNB";
import { Wrapper } from "../../components/Wrapper";

type FormValues = Pick<Reservation, "pregnant">;

export const ReservationStep9 = () => {
  const [reservation, setReservation] = useRecoilState(reservationState);

  useGNB({
    title: useI18N("reservation-step9__title"),
    back: "/reservation/step8",
    progress: useProgress(9),
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (reservation.type === null) {
      navigate("/");
    }
  }, [navigate, reservation.type]);

  const [form] = useForm();
  const handleFinish = useCallback(
    (values?: FormValues) => {
      if (values) {
        setReservation((prev) => ({
          ...prev,
          pregnant: values.pregnant,
        }));
      }

      navigate("/reservation/step10");
    },
    [navigate, setReservation]
  );

  useEffect(() => {
    const initial = {
      pregnant: reservation.pregnant,
    };

    form.setFieldsValue(initial);
  }, [form, reservation]);

  return (
    <Wrapper>
      <Form form={form} onFinish={handleFinish} layout="vertical">
        <SubTitle>
          <I18N name="reservation-step9__subtitle" />
        </SubTitle>

        <Form.Item name="pregnant">
          <RadioGroup style={{ width: "100%" }}>
            <RadioButton
              icon={pregnant}
              iconActive={pregnantActive}
              value={PregnancyStatus.Pregnant}
              iconX="calc(50% + 8px)"
              iconY="30%"
            >
              <div>
                <I18N name="field__pregnant--pregnant" />
              </div>
            </RadioButton>
            <RadioButton
              icon={notPregnant}
              iconActive={notPregnantActive}
              value={PregnancyStatus.NotPregnant}
              iconX="50%"
              iconY="30%"
            >
              <div>
                <I18N name="field__pregnant--not-pregnant" />
              </div>
            </RadioButton>
            <RadioButton
              icon={notSure}
              iconActive={notSureActive}
              value={PregnancyStatus.NotSure}
              iconX="calc(50% + 8px)"
              iconY="30%"
            >
              <div>
                <I18N name="field__pregnant--not-sure" />
              </div>
            </RadioButton>
          </RadioGroup>
        </Form.Item>

        <div style={{ flex: 1 }} />

        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 50 }}
        >
          <button
            type="button"
            onClick={() => handleFinish()}
            style={{
              background: "none",
              color: "#9ea6b4",
              border: "none",
              fontSize: 13,
            }}
          >
            <I18N name="reservation-step9__skip" />
          </button>
        </div>

        <Form.Item dependencies={["pregnant"]} style={{ marginTop: 12 }}>
          {({ getFieldsValue }) => {
            const values: FormValues = getFieldsValue();
            if (values?.pregnant) {
              return (
                <Button type="submit">
                  <I18N name="reservation-step9__submit" />
                </Button>
              );
            }

            return (
              <Button type="submit" disabled>
                <I18N name="reservation-step9__submit--disabled" />
              </Button>
            );
          }}
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

const SubTitle = styled.div`
  font-family: SCoreDream;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 60px;
`;

const RadioGroup = styled(Radio.Group)`
  display: flex;
  justify-content: space-between;
`;

const RadioButton = styled(Radio.Button)<{
  icon: string;
  iconActive: string;
  iconX: string;
  iconY: string;
}>`
  width: calc((100% - 20px) / 3);
  height: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ icon, iconX, iconY }) => `
    background-image: url(${icon});
    background-repeat: no-repeat;
    background-position-x: ${iconX};
    background-position-y: ${iconY};
  `}
  position: relative;
  border-radius: 6px;
  border: 1px #f7f7f7 solid !important;
  background-color: #f7f7f7 !important;
  transition: all 0.3s ease-in-out;

  div {
    position: absolute;
    bottom: 14px;
    left: 50%;
    color: #8e8f97;
    font-size: 14px;
    border-radius: 6px !important;

    left: 0;
    transform: none;
    width: 100%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &::before {
    content: none !important;
  }

  &.ant-radio-button-wrapper-checked {
    border: 1px #0762c8 solid !important;
    background-color: #0762c80c !important;
    ${({ iconActive, iconX, iconY }) => `
      background-image: url(${iconActive});
      background-repeat: no-repeat;
      background-position-x: ${iconX};
      background-position-y: ${iconY};
    `}

    transform: scale(1.02);

    div {
      color: #0762c8 !important;
    }
  }
`;
