import { Form, Radio } from "antd";
import { useForm } from "antd/es/form/Form";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button } from "../../components/Button";
import { I18N, useI18N } from "../../components/I18N";
import { reservationState, useGNB } from "../../atom";
import bullet from "./bullet.svg";
import { useRecoilValue } from "recoil";
import { Wrapper } from "../../components/Wrapper";

type FormValues = {
  question1: boolean;
  question2: boolean;
  question3: boolean;
};

export const Precautions = () => {
  const reservation = useRecoilValue(reservationState);

  useGNB({
    title: useI18N("precautions__title"),
    back: "/",
  });

  const navigate = useNavigate();
  const [form] = useForm();
  const handleFinish = useCallback(
    (values: FormValues) => {
      navigate("/reservation/step1");
    },
    [navigate]
  );

  useEffect(() => {
    if (reservation.type !== null) {
      form.setFieldsValue({
        question1: false,
        question2: false,
        question3: false,
      });
    }
  }, [form, reservation]);

  return (
    <Wrapper>
      <Form form={form} onFinish={handleFinish}>
        <SubTitle>
          <I18N name="precautions__subtitle" />
        </SubTitle>

        <Box>
          <Question>
            <I18N name="precautions__question--1" />
          </Question>
          <Row>
            <Form.Item name="question1" noStyle>
              <Radio.Group name="question1">
                <Radio
                  style={{ minWidth: 110, padding: "12px 0" }}
                  value={true}
                >
                  <I18N name="precautions__radio--yes" />
                </Radio>
                <Radio
                  style={{ minWidth: 110, padding: "12px 0" }}
                  value={false}
                >
                  <I18N name="precautions__radio--no" />
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Row>
        </Box>

        <Box>
          <Question>
            <I18N name="precautions__question--2" />
          </Question>
          <Row>
            <Form.Item name="question2" noStyle>
              <Radio.Group name="question2">
                <Radio
                  style={{ minWidth: 110, padding: "12px 0" }}
                  value={true}
                >
                  <I18N name="precautions__radio--yes" />
                </Radio>
                <Radio
                  style={{ minWidth: 110, padding: "12px 0" }}
                  value={false}
                >
                  <I18N name="precautions__radio--no" />
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Row>
        </Box>

        <Box>
          <Question>
            <I18N name="precautions__question--3" />
          </Question>
          <Row>
            <Form.Item name="question3" noStyle>
              <Radio.Group name="question3">
                <Radio
                  style={{ minWidth: 110, padding: "12px 0" }}
                  value={true}
                >
                  <I18N name="precautions__radio--yes" />
                </Radio>
                <Radio
                  style={{ minWidth: 110, padding: "12px 0" }}
                  value={false}
                >
                  <I18N name="precautions__radio--no" />
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Row>
        </Box>

        <AgreementBox>
          <AgreementCheckbox>
            <I18N name="precautions__agreement--1" />
          </AgreementCheckbox>

          <AgreementCheckbox>
            <I18N name="precautions__agreement--2" />
            <a
              href="mailto:medical-concierge@hitchmed.com"
              style={{
                textDecoration: "underline",
                display: "block",
              }}
            >
              medical-concierge@hitchmed.com
            </a>
          </AgreementCheckbox>
        </AgreementBox>

        <div style={{ flex: 1 }} />

        <Form.Item
          dependencies={[
            "question1",
            "question2",
            "question3",
            "agreement1",
            "agreement2",
          ]}
        >
          {({ getFieldsValue }) => {
            const values: FormValues = getFieldsValue();

            if (
              values.question1 === false &&
              values.question2 === false &&
              values.question3 === false
            ) {
              return (
                <Button type="submit">
                  <I18N name="precautions__submit" />
                </Button>
              );
            }

            return (
              <Button type="submit" disabled>
                <I18N name="precautions__submit--disabled" />
              </Button>
            );
          }}
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
`;

const Question = styled.div`
  font-size: 16px;
  font-family: Noto Sans KR;
  color: #242424;
  font-weight: 400;
`;

const Row = styled.div`
  display: flex;
`;

const SubTitle = styled.div`
  font-family: Noto Sans KR;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 48px;
`;

const AgreementBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const AgreementCheckbox = styled.div`
  background-image: url(${bullet});
  background-repeat: no-repeat;
  background-position: left top 8px;
  padding-left: 20px;
  margin-bottom: 17px;
  margin-inline-start: 0 !important;
  font-family: Noto Sans KR;
  font-weight: 300;
  font-size: 12px !important;
`;
