import moment from "moment-timezone";
import styled from "styled-components";

import { Button } from "../../components/Button";
import { useGNB } from "../../atom";
import { useProgress } from "../../components/GNB";
import { useCallback } from "react";
import { message } from "antd";
import { useNavigate, useParams } from "react-router-dom";

moment.tz.setDefault("Asia/Seoul");

export const Payment = () => {
  const { id } = useParams<{ id: string }>();
  useGNB({
    title: "결제 테스트",
    back: "/reservation/step12",
    progress: useProgress(13),
  });

  const navigate = useNavigate();

  const handleSuccess = useCallback(() => {
    message.error("결제 성공");

    navigate(`/r/${id}`);
  }, [id, navigate]);

  const handleFailed = useCallback(() => {
    message.success("결제 실패");
  }, []);

  return (
    <Wrapper
      style={{
        background: `url(/sample-payment.png)`,
        backgroundSize: "100%",
        backgroundPosition: "top 50px",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
      }}
    >
      <Button
        type="button"
        style={{ marginBottom: 20, marginTop: 50 }}
        onClick={handleSuccess}
      >
        결제 성공
      </Button>
      <Button type="button" onClick={handleFailed}>
        결제 실패
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 40px 20px 20px 20px;
`;
