import { Form, Input, Radio, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import moment from "moment";
import { useCallback, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { every, some } from "lodash";
import { useNavigate } from "react-router-dom";

import { Button } from "../../components/Button";
import { I18N, useI18N } from "../../components/I18N";
import { localeState, Reservation, reservationState, useGNB } from "../../atom";
import iconInfo from "./info.svg";
import iconCalender from "./calender.svg";
import {
  COUNTRIES,
  LANGUAGE_COUNTRY_MAP,
  REGEX_MMDDYYYY,
  REGEX_YYYYMMDD,
  TRANSLATABLE_LANGUAGE,
} from "../../constants";
import { ReservationType } from "../../enums/reservation_type";
import { parseDate } from "../../utils/date";
import { Wrapper } from "../../components/Wrapper";

type FormValues = Pick<
  Reservation,
  "nationality" | "arrival" | "departure" | "travel-insurance"
> & {
  agreement__all: boolean;
  agreement__privacypolicy: boolean;
  agreement__privacypolicy2: boolean;
  agreement__thirdparty: boolean;
};

const AGREEMENT_CHECKBOXES = [
  "agreement__all",
  "agreement__terms",
  "agreement__privacypolicy",
  "agreement__privacypolicy2",
  "agreement__thirdparty",
];

export const ReservationStep3 = () => {
  const navigate = useNavigate();
  const [reservation, setReservation] = useRecoilState(reservationState);

  useEffect(() => {
    if (reservation.type === null) {
      navigate("/");
    }
  }, [navigate, reservation.type]);

  const fieldArrivalPlaceholder = useI18N("field__arrival--placeholder");
  const fieldDeparturePlaceholder = useI18N("field__departure--placeholder");

  useGNB({
    title: useI18N("reservation-step3__title"),
    back: "/reservation/step2",
  });

  const [form] = useForm();
  const handleFinish = useCallback(
    (values: FormValues) => {
      setReservation((prev) => ({
        ...prev,
        ...values,
      }));

      navigate("/reservation/step4");
    },
    [navigate, setReservation]
  );

  useEffect(() => {
    form.setFieldsValue(reservation);
  }, [form, reservation]);

  const handleChange = useCallback(() => {
    const all = every(Object.values(form.getFieldsValue(AGREEMENT_CHECKBOXES)));

    form.setFieldsValue({
      agreement__all: all,
    });
  }, [form]);

  const locale = useRecoilValue(localeState);

  useEffect(() => {
    const preferredLanguage = form.getFieldValue("preferred-language");

    if (!preferredLanguage) {
      const values: any = {
        "preferred-language": locale,
      };

      if (!values.nationality && LANGUAGE_COUNTRY_MAP[locale]) {
        values.nationality = LANGUAGE_COUNTRY_MAP[locale];
      }

      form.setFieldsValue(values);
      form.validateFields(["nationality"]);
    }
  }, [form, locale]);

  return (
    <Wrapper>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleFinish}
        onChange={handleChange}
      >
        <SubTitle>
          <I18N name="reservation-step3__subtitle" />
        </SubTitle>

        <Form.Item
          label={<I18N name="field__nationality" />}
          name="nationality"
          rules={[
            {
              required: true,
              message: "nationality_required",
            },
          ]}
        >
          <Select defaultOpen={!reservation?.nationality}>
            {COUNTRIES.map((country) => (
              <Select.Option value={country} key={country}>
                <I18N name={`country--${country}`} />
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Info noMark>
          <I18N name="field__nationality--info" />
        </Info>

        {reservation.type === ReservationType.Standard && (
          <Form.Item
            label={<I18N name="field__preferred-language" />}
            name="preferred-language"
            rules={[
              {
                required: true,
                message: "preferred-language_required",
              },
            ]}
          >
            <Select>
              {TRANSLATABLE_LANGUAGE.map((language) => (
                <Select.Option value={language} key={language}>
                  <I18N name={`language--${language}`} />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          name="arrival"
          label={<I18N name="field__arrival" />}
          rules={[
            {
              required: true,
              message: "arrival_required",
            },
            {
              validator: async (_, value) => {
                const date = parseDate(value);

                if (moment(date).add(3, "months").isBefore()) {
                  throw new Error("invalid_arrival");
                }

                if (moment(date).isAfter()) {
                  throw new Error("invalid_arrival");
                }
              },
            },
            {
              validator: async (_, value: string) => {
                const v = value.replace(/[^0-9]/g, "");

                if (REGEX_MMDDYYYY.test(v)) {
                  return true;
                } else if (REGEX_YYYYMMDD.test(v)) {
                  return true;
                }

                throw new Error("");
              },
              message: "arrival_pattern",
            },
          ]}
          normalize={(value: string, prevValue: string) => {
            const v = value.replace(/[^0-9]/g, "");

            if (REGEX_MMDDYYYY.test(v)) {
              return v.replace(REGEX_MMDDYYYY, "$1/$2/$3");
            } else if (REGEX_YYYYMMDD.test(v)) {
              return v.replace(REGEX_YYYYMMDD, "$1-$2-$3");
            }

            return v;
          }}
        >
          <Input
            placeholder={fieldArrivalPlaceholder}
            inputMode="numeric"
            type="text"
            formNoValidate
            style={{
              border: "none",
              backgroundImage: `url(${iconCalender})`,
              backgroundPosition: "right 10px center",
              backgroundRepeat: "no-repeat",
            }}
          />
        </Form.Item>
        <Info noMark>
          <I18N name="field__arrival--info" />
        </Info>

        <Form.Item
          name="departure"
          label={<I18N name="field__departure" />}
          rules={[
            {
              required: true,
              message: "departure_required",
            },
            {
              validator: async (_, value: string) => {
                const v = value.replace(/[^0-9]/g, "");

                if (REGEX_MMDDYYYY.test(v)) {
                  return true;
                } else if (REGEX_YYYYMMDD.test(v)) {
                  return true;
                }

                throw new Error("");
              },
              message: "departure_pattern",
            },
            {
              validator: async (rule, value) => {
                const arrival = parseDate(form.getFieldValue("arrival"));
                const departure = parseDate(value);

                if (!arrival || !departure) {
                  return;
                }

                if (moment(arrival).add(3, "month").isBefore(departure)) {
                  throw new Error("invalid_departure");
                }

                if (
                  moment.max(moment(), arrival).add(1, "day").isAfter(departure)
                ) {
                  throw new Error("invalid_departure");
                }
              },
            },
          ]}
          normalize={(value: string, prevValue: string) => {
            const v = value.replace(/[^0-9]/g, "");

            if (REGEX_MMDDYYYY.test(v)) {
              return v.replace(REGEX_MMDDYYYY, "$1/$2/$3");
            } else if (REGEX_YYYYMMDD.test(v)) {
              return v.replace(REGEX_YYYYMMDD, "$1-$2-$3");
            }

            return v;
          }}
        >
          <Input
            placeholder={fieldDeparturePlaceholder}
            inputMode="numeric"
            type="text"
            formNoValidate
            style={{
              border: "none",
              backgroundImage: `url(${iconCalender})`,
              backgroundPosition: "right 10px center",
              backgroundRepeat: "no-repeat",
            }}
          />
        </Form.Item>
        <Info noMark>
          <I18N name="field__departure--info" />
        </Info>

        <Form.Item
          name="travel-insurance"
          label={
            <div>
              <I18N name="field__travel-insurance" />
              <br />
              <span style={{ color: "#A9A9A9" }}>
                <I18N name="field__travel-insurance--info" />
              </span>
            </div>
          }
        >
          <Radio.Group>
            <Radio value={true} style={{ padding: "8px 0" }}>
              <I18N name="field__travel-insurance--insured" />
            </Radio>
            <Radio value={false} style={{ padding: "8px 0" }}>
              <I18N name="field__travel-insurance--not-insured" />
            </Radio>
          </Radio.Group>
        </Form.Item>

        <div style={{ flex: 1 }} />

        <Form.Item shouldUpdate>
          {({ getFieldsError, isFieldsTouched }) => {
            const fields = ["arrival", "departure", "travel-insurance"];

            if (reservation.type === ReservationType.Standard) {
              fields.push("preferred-language");
            }

            const isTouched = isFieldsTouched(fields, true);
            const errors = getFieldsError();

            const error =
              !isTouched || some(errors, (field) => !!field.errors.length);

            return (
              <Button type="submit" style={{ marginTop: 0 }} disabled={error}>
                <I18N name="reservation-step3__submit" />
              </Button>
            );
          }}
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

const SubTitle = styled.div`
  font-family: SCoreDream;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 24px;
`;

const Info = styled.div<{ noMark?: boolean }>`
  ${({ noMark }) =>
    noMark !== true
      ? `
        background-image: url(${iconInfo});
        background-repeat: no-repeat;
        background-position: left top 4px;
        padding-left: 19px;
      `
      : `
        padding-left: 23px;
        text-indent: -16px;
      `}
  font-family: Noto Sans KR;
  font-size: 12px;
  color: #4f4f4f;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: -20px;
  margin-bottom: 20px;
`;
