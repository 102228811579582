import { FC, useCallback, useMemo, useState } from "react";
import { range } from "lodash";
import moment, { Moment } from "moment-timezone";
import styled from "styled-components";

import { I18N, useI18N } from "../I18N";
import bullet from "./bullet.svg";
import bulletActive from "./bullet--active.svg";
import prev from "./prev.svg";
import next from "./next.svg";

moment.tz.setDefault("Asia/Seoul");

type ReservationCalendarProps = {
  min: Moment;
  max: Moment;
  value?: Moment;
  onChange?: { (value: Moment): unknown };
  marks: Moment[];
};

export const ReservationCalendar: FC<ReservationCalendarProps> = ({
  min,
  max,
  value,
  onChange,
  marks,
}) => {
  const todayLabel = useI18N("reservation-calendar__today-label");

  const [currentMonth, setCurrentMonth] = useState(min);
  const { days, weekCount } = useMemo(() => {
    const start = moment(currentMonth).startOf("month").startOf("week");
    const end = moment(currentMonth).endOf("month").endOf("week");
    const days = [];

    for (let i = 0; i <= end.diff(start, "days"); i++) {
      const date = moment(start).add(i, "days");
      days.push({
        thisMonth: date.isSame(currentMonth, "month"),
        available:
          date.isSameOrAfter(moment.max(min, moment()), "date") &&
          date.isSameOrBefore(max),
        today: date.isSame(moment(), "date"),
        date,
        saturday: date.day() === 6,
        holiday: date.day() === 0,
      });
    }

    const weekCount = Math.ceil(days.length / 7);

    return {
      start,
      end,
      days,
      weekCount,
    };
  }, [currentMonth, max, min]);

  const handlePrev = useCallback(() => {
    setCurrentMonth((prev) => prev.clone().subtract(1, "month"));
  }, []);

  const handleNext = useCallback(() => {
    setCurrentMonth((prev) => prev.clone().add(1, "month"));
  }, []);

  const handleClickDate = useCallback(
    (date: moment.Moment) => {
      onChange?.(date);

      if (!date.isSame(currentMonth, "month")) {
        setCurrentMonth(date);
      }
    },
    [currentMonth, onChange]
  );

  const canPrev = useMemo(() => {
    return currentMonth.isSameOrBefore(moment.max(moment(), min), "month");
  }, [currentMonth, min]);

  const canNext = useMemo(() => {
    return currentMonth.isSameOrAfter(max, "month");
  }, [currentMonth, max]);

  return (
    <Wrapper>
      <Header>
        <Year>{currentMonth.format("YYYY")}</Year>
        <MonthSelector>
          <PrevButton type="button" onClick={handlePrev} disabled={canPrev} />
          <CurrentMonth>
            <I18N
              name={`reservation-date-selector__month--${currentMonth.format(
                "M"
              )}`}
            />
          </CurrentMonth>
          <NextButton type="button" onClick={handleNext} disabled={canNext} />
        </MonthSelector>
        <div style={{ flex: 1 }} />
      </Header>
      <Row>
        <Day>
          <I18N name="reservation-date-selector__day--mon" />
        </Day>
        <Day>
          <I18N name="reservation-date-selector__day--tue" />
        </Day>
        <Day>
          <I18N name="reservation-date-selector__day--wed" />
        </Day>
        <Day>
          <I18N name="reservation-date-selector__day--thu" />
        </Day>
        <Day>
          <I18N name="reservation-date-selector__day--fri" />
        </Day>
        <Day>
          <I18N name="reservation-date-selector__day--sat" />
        </Day>
        <Day>
          <I18N name="reservation-date-selector__day--sun" />
        </Day>
      </Row>

      {range(weekCount).map((week) => (
        <Row key={week}>
          {days.slice(week * 7, week * 7 + 7).map((day) => {
            const mark = marks.find((i) => i.isSame(day.date, "date"));

            return (
              <Date
                key={day.date.format("YYYY-MM-DD")}
                today={day.today}
                available={day.available}
                todayLabel={todayLabel}
                selected={value && !!day.date.isSame(value, "date")}
                onClick={() => handleClickDate(day.date)}
                thisMonth={day.thisMonth}
                mark={!!mark}
                saturday={day.saturday}
                holiday={day.holiday}
                type="button"
              >
                <i>{day.date.format("D")}</i>
                {mark && (
                  <div className="message">
                    <I18N
                      name={`reservation-date-selector__${
                        mark.get("hour") < 12 ? "am" : "pm"
                      }`}
                    />
                  </div>
                )}
              </Date>
            );
          })}
        </Row>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  height: 57px;
  position: relative;
`;

const Year = styled.div`
  font-size: 12px;
  color: #615e83;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const MonthSelector = styled.div`
  width: 173px;
  display: flex;
`;

const PrevButton = styled.button`
  background: none;
  background-image: url(${prev});
  background-repeat: no-repeat;
  background-position: left 20px center;
  border: none;
  flex: 1;
  height: 57px;

  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.5;
      pointer-event: none;
      cursor: default;
    `}
`;

const CurrentMonth = styled.div`
  font-size: 16px;
  color: #515151;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NextButton = styled.button`
  background: none;
  background-image: url(${next});
  background-repeat: no-repeat;
  background-position: right 20px center;
  border: none;
  flex: 1;
  height: 57px;

  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.5;
      pointer-event: none;
      cursor: default;
    `}
`;

const Row = styled.div`
  width: 100%;
  display: flex;
`;

const Day = styled.div`
  height: 15px;
  flex: 1;
  font-size: 10px;
  display: flex;
  align-items: top;
  justify-content: center;
  color: #00000066;
  margin-top: 5px;
  margin-bottom: 12px;
`;

const Date = styled.button<{
  today?: boolean;
  saturday?: boolean;
  holiday?: boolean;
  selected?: boolean;
  todayLabel: string;
  available?: boolean;
  thisMonth?: boolean;
  mark?: boolean;
}>`
  height: 40px;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  transform: 200ms all;

  ${({ today, mark }) => {
    if (!today && !mark) {
      return `
        align-items: center;
        justify-content: center;
        font-size: 12px;
      `;
    }
    return `
      align-items: center;
      justify-content: top;
      font-size: 10px;
    `;
  }}

  ${({ saturday, holiday, today, selected, thisMonth, available, mark }) => {
    let border = "1px #ffffff solid";
    let background = "#ffffff";
    let color;
    let opacity = 1;

    if (saturday) {
      color = "#0000ff";
    } else if (holiday) {
      color = "#ff0000";
    } else {
      color = "#000000";
    }

    if (!thisMonth || !available) {
      color = `${color}66`;
    }

    if (!thisMonth) {
      opacity = 0.5;
    }

    if (selected) {
      if (thisMonth) {
        if (!saturday && !holiday) {
          color = "#0762C8";
        }
        background = "#1C7BE612";
        border = "1px #0762C8 solid";
      } else {
        if (!saturday && !holiday) {
          color = "#0762C880";
        }
        background = "#1C7BE61280";
        border = "1px #0762C880 solid";
      }
    } else if (mark) {
      background = "#0762C8";
      color = "#ffffff";
    } else if (today) {
      if (!saturday && !holiday) {
        color = "#0762C8";
      }
      background = "#F3F3F4";
    }

    return `
      background-color: ${background};
      border: ${border};
      color: ${color};
      opacity: ${opacity};

      .message {
        background-image: url(${selected ? bulletActive : bullet});
        padding-left: 14px;
        font-weight: bold;
        background-repeat: no-repeat;
        background-position: left center;
        background-repeat: no-repeat;
        color: ${selected && "#0762C8"};
      }
    `;
  }}

  ${({ available, mark }) => `
    pointer-events: ${available && !mark ? "auto" : "none"};
  `}

  i {
    font-style: normal;
    display: block;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    ${({ selected }) => selected && "font-weight: bold;"}

    ${({ today, todayLabel, mark }) =>
      !mark &&
      today &&
      `
        font-weight: bold;
        line-height: 1;
        padding-top: 2px;

        &::after {
          content: "${todayLabel}";
          display: block;
          font-weight: normal;
          padding-top: 2px;
        }
      `}
  }
`;
