import moment from "moment-timezone";

moment.tz.setDefault("Asia/Seoul");

export const REGEX_YYYYMMDD = /^(19\d\d|2[01234]\d\d)(0\d|1[012])([0123]\d)$/;
export const REGEX_MMDDYYYY = /^(0\d|1[012])([0123]\d)(19\d\d|2[01234]\d\d)$/;
export const COUNTRIES = ["kr", "us", "jp", "cn"];
export const TRANSLATABLE_LANGUAGE = ["ko", "en", "ja", "zh"];
export const LANGUAGE_COUNTRY_MAP = {
  ko: "kr",
  en: "us",
  ja: "jp",
  zh: "cn",
};
