import { useRecoilValue } from "recoil";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { gnbState, reservationState } from "../../atom";
import left from "./left.svg";
import { LocaleButton } from "../Locale/LocaleButton";
import { Popconfirm } from "antd";

export const GNB = () => {
  const { back, title, progress } = useRecoilValue(gnbState);
  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    if (!back) {
      return;
    }

    if (back === true) {
      navigate(-1);
    } else {
      navigate(back);
    }
  }, [back, navigate]);

  return (
    <Wrapper id="gnb" progress={progress}>
      {back && <Back onClick={handleBack} />}
      <div>
        <Popconfirm
          title={"캐시를 초기화할까요?"}
          onConfirm={() => {
            window.localStorage.clear();
            window.location.href = "/";
          }}
        >
          {title}
        </Popconfirm>
      </div>
      <Right>
        <LocaleButton />
      </Right>
    </Wrapper>
  );
};

export const useProgress = (step: number) => {
  const reservation = useRecoilValue(reservationState);

  const progress = useMemo(() => {
    const curr = step - 8 - (reservation._checkPregnancy ? 0 : 1);
    // TODO: 공통 문진 갯수 만큼 여기를 늘려야 됨. 현재는 총 3개 + 1개(임신)
    const max = 4 + (reservation._checkPregnancy ? 1 : 0);

    return curr / max;
  }, [reservation._checkPregnancy, step]);

  return progress;
};

const Wrapper = styled.div<{ progress?: number }>`
  width: 100%;
  min-height: 56px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: Noto Sans KR;
  position: sticky;
  background-color: #ffffff;
  top: 0;
  z-index: 50;

  &::before {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    height: 4px;
    width: 100%;
    background-color: #dbe2e4;
    z-index: 1;
    opacity: ${({ progress }) => {
      if (progress === 1) {
        return 0;
      }

      return progress ? 1 : 0;
    }};
    transition: 1000ms all;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    height: 4px;
    width: ${({ progress }) => {
      if (progress === 1) {
        return 100;
      }

      return 100 * (progress || 0);
    }}%;
    background-color: #0762c8;
    z-index: 2;
    transition: 400ms all;
    opacity: ${({ progress }) => {
      if (progress === 1) {
        return 0;
      }

      return progress ? 1 : 0;
    }};
  }
`;
//opacity: ${({ progress }) => (progress ? 1 : 0)};

const Back = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  background: url(${left}) no-repeat;
  background-position: left 19px center;
  width: 60px;
  height: 56px;
  border: none;
  outline: none;
`;

const Right = styled.div`
  position: absolute;
  right: 0;
`;
