import { Form, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { useCallback, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button } from "../../components/Button";
import { I18N, useI18N } from "../../components/I18N";
import { localeState, Reservation, reservationState, useGNB } from "../../atom";
import { LanguageSelector } from "../../components/LanguageSelector";
import { Hotel } from "../../components/Hotel";
import { Wrapper } from "../../components/Wrapper";

type FormValues = Pick<
  Reservation,
  "request-language" | "accompanying-persons"
>;

export const ReservationStep6 = () => {
  const locale = useRecoilValue(localeState);
  const [reservation, setReservation] = useRecoilState(reservationState);
  const navigate = useNavigate();

  useEffect(() => {
    if (reservation.type === null) {
      navigate("/");
    }
  }, [navigate, reservation.type]);

  useGNB({
    title: useI18N("reservation-step6__title"),
    back: "/reservation/step5",
  });

  const [form] = useForm();
  const handleFinish = useCallback(
    (values: FormValues) => {
      const requestLanguage = (values["request-language"] || []).filter(
        (i) => i
      );
      setReservation((prev) => ({
        ...prev,
        "request-language": requestLanguage,
        "accompanying-persons": values["accompanying-persons"],
      }));

      navigate("/reservation/step7");
    },
    [navigate, setReservation]
  );

  useEffect(() => {
    const initial: Partial<FormValues> = {};

    if (reservation["request-language"]) {
      initial["request-language"] = reservation["request-language"];
    }
    if (reservation["accompanying-persons"]) {
      initial["accompanying-persons"] = reservation["accompanying-persons"];
    }

    form.setFieldsValue(initial);
  }, [form, reservation]);

  return (
    <Wrapper>
      <Form form={form} onFinish={handleFinish} layout="vertical">
        <SubTitle>
          <I18N name="reservation-step6__subtitle" />
        </SubTitle>

        <Form.Item
          name="request-language"
          label={<I18N name="field__request-language" />}
          initialValue={[locale]}
          rules={[
            {
              validator: async (_, value: string[]) => {
                if ((value || []).filter((i) => i).length >= 1) {
                  return;
                }

                throw new Error("");
              },
              message: "birthday_pattern",
            },
          ]}
        >
          <LanguageSelector defaultOpen={!reservation?.["request-language"]} />
        </Form.Item>

        <Form.Item
          name="accompanying-persons"
          label={<I18N name="field__accompanying-persons" />}
          initialValue={0}
        >
          <Select>
            {[0, 1, 2].map((i) => (
              <Select.Option value={i} key={i}>
                <I18N name={`accompanying-persons--${i}`} />
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="hotel" label={<I18N name="field__hotel" />}>
          <Hotel
            thumbnail={"/sample-logo.png"}
            name="파라다이스 시티"
            address="인천 중구 영종해안남로321번길 186"
          />
        </Form.Item>

        <Form.Item name="hotel" label={<I18N name="field__hotel" />}>
          <>
            <div
              style={{
                width: "100%",
                background: "#D9DBE1",
                height: 1,
                marginBottom: 16,
                marginTop: 12,
              }}
            />
            <Bullet>
              <I18N name="hotel_notice--1" />
            </Bullet>
            <Bullet>
              <I18N name="hotel_notice--2" />
            </Bullet>
            <Bullet>
              <I18N name="hotel_notice--3" />
            </Bullet>
            <Bullet>
              <I18N name="hotel_notice--4" />
            </Bullet>
            <Bullet>
              <I18N name="hotel_notice--5" />
            </Bullet>
          </>
        </Form.Item>

        <div style={{ flex: 1 }} />

        <Form.Item
          dependencies={["request-language", "accompanying-persons"]}
          style={{ marginTop: 50 }}
        >
          {({ getFieldsValue }) => {
            const values: FormValues = getFieldsValue();
            const requestLanguage = values["request-language"]?.filter(
              (i) => i
            );

            if (requestLanguage?.length) {
              return (
                <Button type="submit">
                  <I18N name="reservation-step6__submit" />
                </Button>
              );
            }

            return (
              <Button type="submit" disabled>
                <I18N name="reservation-step6__submit--disabled" />
              </Button>
            );
          }}
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

const SubTitle = styled.div`
  font-family: SCoreDream;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 24px;
`;

const Bullet = styled.div`
  font-size: 12px;
  line-height: 20px;

  &::before {
    content: "• "; //·
  }
`;
