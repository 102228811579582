import { Checkbox, Form, Input, Radio } from "antd";
import { useForm } from "antd/es/form/Form";
import moment from "moment-timezone";
import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import validator from "validator";
import { every, some } from "lodash";
import { useNavigate } from "react-router-dom";

import { Button } from "../../components/Button";
import { I18N, useI18N } from "../../components/I18N";
import { Reservation, reservationState, useGNB } from "../../atom";
import iconInfo from "./info.svg";
import iconCalender from "./calender.svg";
import { REGEX_MMDDYYYY, REGEX_YYYYMMDD } from "../../constants";
import { parseDate } from "../../utils/date";
import { Wrapper } from "../../components/Wrapper";

moment.tz.setDefault("Asia/Seoul");

type FormValues = Pick<
  Reservation,
  "firstName" | "lastName" | "birthday" | "gender" | "email" | "_checkPregnancy"
> & {
  agreement__all: boolean;
  agreement__privacypolicy: boolean;
  agreement__privacypolicy2: boolean;
  agreement__thirdparty: boolean;
};

const AGREEMENT_CHECKBOXES = [
  "agreement__all",
  "agreement__terms",
  "agreement__privacypolicy",
  "agreement__privacypolicy2",
  "agreement__thirdparty",
];

export const ReservationStep2 = () => {
  const navigate = useNavigate();
  const [reservation, setReservation] = useRecoilState(reservationState);

  useEffect(() => {
    if (reservation.type === null) {
      navigate("/");
    }
  }, [navigate, reservation.type]);

  const fieldFirstNamePlaceholder = useI18N("field__first-name--placeholder");
  const lastNamePlaceholder = useI18N("field__last-name--placeholder");
  const fieldBirthdayPlaceholder = useI18N("field__birthday--placeholder");
  const fieldEmailPlaceholder = useI18N("field__email--placeholder");

  useGNB({
    title: useI18N("reservation-step2__title"),
    back: "/reservation/step1",
  });

  const [form] = useForm();
  const handleFinish = useCallback(
    (values: FormValues) => {
      const birthday = parseDate(values.birthday!);
      const age = moment().diff(birthday, "years");
      const _checkPregnancy =
        values.gender === "female" && age >= 12 && age < 50;

      setReservation((prev) => ({
        ...prev,
        ...values,
        _checkPregnancy,
      }));

      navigate("/reservation/step3");
    },
    [navigate, setReservation]
  );

  useEffect(() => {
    form.setFieldsValue(reservation);
  }, [form, reservation]);

  const handleToggleAgreementAll = useCallback(
    (checked: boolean) => {
      form.setFieldsValue({
        agreement__terms: checked,
        agreement__privacypolicy: checked,
        agreement__privacypolicy2: checked,
        agreement__thirdparty: checked,
      });
      form.validateFields(AGREEMENT_CHECKBOXES);
    },
    [form]
  );

  const handleChange = useCallback(() => {
    const all = every(Object.values(form.getFieldsValue(AGREEMENT_CHECKBOXES)));

    form.setFieldsValue({
      agreement__all: all,
    });
  }, [form]);

  return (
    <Wrapper>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleFinish}
        onChange={handleChange}
      >
        <SubTitle>
          <I18N name="reservation-step2__subtitle" />
        </SubTitle>

        <Row>
          <Form.Item
            style={{ flex: 1, marginRight: 8 }}
            name="firstName"
            label={<I18N name="field__first-name" />}
            rules={[
              {
                required: true,
                message: "firstName_required",
              },
              {
                min: 1,
                message: "firstName_min",
              },
              {
                max: 50,
                message: "firstName_max",
              },
              {
                pattern: /^[^0-9~!@#$%^&*()+_ㄱ-ㅎㅏ-ㅢ]+$/,
                message: "firstName_pattern",
              },
            ]}
            normalize={(value: string) => value.trim().replace(/ {2}/g, " ")}
          >
            <Input placeholder={fieldFirstNamePlaceholder} autoFocus />
          </Form.Item>
          <Form.Item
            style={{ flex: 1, marginLeft: 8 }}
            name="lastName"
            label={<I18N name="field__last-name" />}
            rules={[
              {
                required: true,
                message: "lastName_required",
              },
              {
                min: 1,
                message: "lastName_min",
              },
              {
                max: 50,
                message: "lastName_max",
              },
              {
                pattern: /^[^0-9~!@#$%^&*()+_ㄱ-ㅎㅏ-ㅢ]+$/,
                message: "lastName_pattern",
              },
            ]}
            normalize={(value: string) => value.trim().replace(/ {2}/g, " ")}
          >
            <Input placeholder={lastNamePlaceholder} />
          </Form.Item>
        </Row>

        <Form.Item
          name="birthday"
          label={<I18N name="field__birthday" />}
          rules={[
            {
              required: true,
              message: "birthday_required",
            },
            {
              validator: async (_, value: string) => {
                const v = value.replace(/[^0-9]/g, "");

                if (REGEX_MMDDYYYY.test(v)) {
                  return true;
                } else if (REGEX_YYYYMMDD.test(v)) {
                  return true;
                }

                throw new Error("");
              },
              message: "birthday_pattern",
            },
          ]}
          normalize={(value: string, prevValue: string) => {
            const v = value.replace(/[^0-9]/g, "");

            if (REGEX_MMDDYYYY.test(v)) {
              return v.replace(REGEX_MMDDYYYY, "$1/$2/$3");
            } else if (REGEX_YYYYMMDD.test(v)) {
              return v.replace(REGEX_YYYYMMDD, "$1-$2-$3");
            }

            return v;
          }}
        >
          <Input
            placeholder={fieldBirthdayPlaceholder}
            inputMode="numeric"
            type="text"
            formNoValidate
            style={{
              border: "none",
              backgroundImage: `url(${iconCalender})`,
              backgroundPosition: "right 10px center",
              backgroundRepeat: "no-repeat",
            }}
          />
        </Form.Item>

        <Form.Item name="gender" label={<I18N name="field__gender" />}>
          <Radio.Group>
            <Radio value="male" style={{ padding: "8px 0" }}>
              <I18N name="field__gender--male" />
            </Radio>
            <Radio value="female" style={{ padding: "8px 0" }}>
              <I18N name="field__gender--female" />
            </Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="email"
          label={<I18N name="field__email" />}
          messageVariables={{}}
          rules={[
            {
              validator: async (_, value) => {
                if (!validator.isEmail(value)) {
                  throw new Error();
                }
              },
              message: "email_syntax",
            },
          ]}
          normalize={(value: string) => value.trim().replace(/ /g, "")}
        >
          <Input placeholder={fieldEmailPlaceholder} />
        </Form.Item>
        <Info style={{ marginTop: -20, marginBottom: 20 }}>
          <I18N name="field__email--info" />
        </Info>

        <Form.Item name="agreement__all" valuePropName="checked">
          <Checkbox
            onClick={(e) => handleToggleAgreementAll((e.target as any).checked)}
          >
            <I18N name="field__agreement-checkbox--all" />
          </Checkbox>
        </Form.Item>

        <RowAgreement>
          <Form.Item
            valuePropName="checked"
            rules={[
              {
                validator: async (_, value) => {
                  if (!value) {
                    throw new Error();
                  }
                },
                message: "terms_checked",
              },
            ]}
            name="agreement__terms"
            noStyle
          >
            <Checkbox>
              <I18N name="field__agreement-checkbox--terms" />
            </Checkbox>
          </Form.Item>
          <CheckboxDetailButton>
            <I18N name="field__agreement-checkbox--detail" />
          </CheckboxDetailButton>
        </RowAgreement>

        <RowAgreement>
          <Form.Item
            valuePropName="checked"
            rules={[
              {
                validator: async (_, value) => {
                  if (!value) {
                    throw new Error();
                  }
                },
                message: "privacypolicy_checked",
              },
            ]}
            name="agreement__privacypolicy"
            noStyle
          >
            <Checkbox>
              <I18N name="field__agreement-checkbox--privacy-policy" />
            </Checkbox>
          </Form.Item>
          <CheckboxDetailButton>
            <I18N name="field__agreement-checkbox--detail" />
          </CheckboxDetailButton>
        </RowAgreement>

        <RowAgreement>
          <Form.Item
            valuePropName="checked"
            rules={[
              {
                validator: async (_, value) => {
                  if (!value) {
                    throw new Error();
                  }
                },
                message: "privacypolicy2_checked",
              },
            ]}
            name="agreement__privacypolicy2"
            noStyle
          >
            <Checkbox>
              <I18N name="field__agreement-checkbox--privacy-policy-2" />
            </Checkbox>
          </Form.Item>
          <CheckboxDetailButton>
            <I18N name="field__agreement-checkbox--detail" />
          </CheckboxDetailButton>
        </RowAgreement>

        <RowAgreement>
          <Form.Item
            valuePropName="checked"
            rules={[
              {
                validator: async (_, value) => {
                  if (!value) {
                    throw new Error();
                  }
                },
                message: "thirdparty_checked",
              },
            ]}
            name="agreement__thirdparty"
            noStyle
          >
            <Checkbox>
              <I18N name="field__agreement-checkbox--third-party" />
            </Checkbox>
          </Form.Item>
          <CheckboxDetailButton>
            <I18N name="field__agreement-checkbox--detail" />
          </CheckboxDetailButton>
        </RowAgreement>

        <div style={{ flex: 1 }} />

        <Form.Item shouldUpdate>
          {({ getFieldsError, isFieldsTouched }) => {
            const isTouched = isFieldsTouched(
              [
                "firstName",
                "lastName",
                "birthday",
                "gender",
                "email",
                ...AGREEMENT_CHECKBOXES,
              ],
              true
            );
            const errors = getFieldsError();

            const error =
              !isTouched || some(errors, (field) => !!field.errors.length);

            return (
              <Button type="submit" style={{ marginTop: 0 }} disabled={error}>
                <I18N name="reservation-step2__submit" />
              </Button>
            );
          }}
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

const SubTitle = styled.div`
  font-family: SCoreDream;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 24px;
`;

const Row = styled.div`
  display: flex;
`;

const RowAgreement = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 9px;
  margin-bottom: 14px;
`;

const Info = styled.div`
  background-image: url(${iconInfo});
  background-repeat: no-repeat;
  background-position: left top 4px;
  padding-left: 19px;
  font-family: Noto Sans KR;
  font-size: 12px;
  color: #4f4f4f;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 6px;
`;

const CheckboxDetailButton = styled.button`
  border: none;
  background: none;
  font-family: Noto Sans KR;
  font-size: 11px;
  color: #a0a0a0;
  white-space: nowrap;
  padding-top: 1px;
`;
