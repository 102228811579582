import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button } from "../../components/Button";
import { I18N } from "../../components/I18N";
import { useSize } from "../../enums/size";
import { useGNB } from "../../atom";
import { Logo } from "./Logo";
import { Slider } from "./Slider";
import { Wrapper } from "../../components/Wrapper";

export const Home = () => {
  useGNB({
    title: null,
    back: false,
  });

  const { w } = useSize();
  const navigate = useNavigate();

  const i18nButtonName = useMemo(() => {
    if (w < 400) {
      return "home__button--next-short";
    }

    return "home__button--next";
  }, [w]);

  const handleClickBook = useCallback(() => {
    navigate("/precautions");
  }, [navigate]);

  return (
    <Wrapper>
      <div>
        <Logo url="/sample-logo.png" />
        <Title>
          <I18N name="home__title" />
        </Title>

        <Slider />
      </div>
      <ButtonBox>
        <Button buttonStyle="main" onClick={handleClickBook}>
          <I18N name={i18nButtonName} />
        </Button>
      </ButtonBox>
    </Wrapper>
  );
};

const Title = styled.div`
  font-family: SCoreDream;
  text-align: center;
`;

const ButtonBox = styled.div`
  width: 100%;
  max-width: 500px;
  margin: auto;
`;
