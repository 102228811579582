import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

import iconClose from "./icon__close.svg";

const DIALOG_SCROLL_FREEZE = "dialog-scroll-freeze";

const freezeScroll = () => {
  const body = document.body;
  if (body.classList.contains(DIALOG_SCROLL_FREEZE)) {
    return;
  }

  body.classList.add(DIALOG_SCROLL_FREEZE);
  const scrollY = document.documentElement.style.getPropertyValue("--scroll-y");
  body.style.position = "fixed";
  body.style.top = `-${scrollY}`;
};

const releaseScroll = () => {
  const body = document.body;
  if (!body.classList.contains(DIALOG_SCROLL_FREEZE)) {
    return;
  }

  body.classList.remove(DIALOG_SCROLL_FREEZE);
  const scrollY = body.style.top;
  body.style.position = "";
  body.style.top = "";
  window.scrollTo(0, parseInt(scrollY || "0") * -1);
};

export const Portal: FC<{ children: ReactNode }> = ({ children }) => {
  const modal = document.querySelector("#global-modal")!;

  return createPortal(children, modal);
};

export const Dialog: FC<{
  open: boolean;
  children: ReactNode;
  onClose?: { (): boolean };
}> = ({ open, children, onClose }) => {
  const [render, setRender] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let animFrame: number;
    let timer: NodeJS.Timeout;

    if (open) {
      setRender(true);
      setVisible(false);
      animFrame = requestAnimationFrame(() => {
        animFrame = requestAnimationFrame(() => {
          setVisible(true);
        });
      });
    } else {
      setVisible(false);

      animFrame = requestAnimationFrame(() => {
        timer = setTimeout(() => {
          setRender(false);
        }, 1000);
      });
    }

    return () => {
      if (animFrame) {
        cancelAnimationFrame(animFrame);
      }
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [open]);

  useEffect(() => {
    const handleScroll = () => {
      document.documentElement.style.setProperty(
        "--scroll-y",
        `${window.scrollY}px`
      );
    };
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  });

  useEffect(() => {
    if (open) {
      freezeScroll();
    } else {
      releaseScroll();
    }
  }, [open]);

  const handleClose = useCallback(() => {
    if (onClose && onClose()) {
      return;
    }

    releaseScroll();
  }, [onClose]);

  return (
    <Portal>
      {render && <Cover visible={visible} onClick={handleClose} />}
      {render && (
        <Modal visible={visible}>
          <ModalClose onClick={handleClose} />
          <ModalContent>{children}</ModalContent>
        </Modal>
      )}
    </Portal>
  );
};

Dialog.displayName = "Dialog";

const Cover = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  transition: 400ms opacity;
  z-index: 99;

  ${({ visible }) =>
    visible
      ? `
          pointer-events: all;
          opacity: 1;
        `
      : `
          pointer-events: none;
          opacity: 0;
        `}
`;

const Modal = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: calc(min(100vw - 40px, 500px));
  background-color: white;
  border-radius: 10px;
  z-index: 100;
  padding: 38px 12px 12px 12px;
  transition: 300ms all;
  opacity: 0;
  transform: scale(0.98) translate(-50%, -42%);
  transform-origin: 0 -30%;
  pointer-events: none;

  ${({ visible }) =>
    visible &&
    `
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
    pointer-events: all;
  `}
`;

const ModalClose = styled.button`
  background: url(${iconClose});
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  top: 0;
  right: 0;
  position: absolute;
  cursor: pointer;
  border: none;
  transition: 400ms opacity;

  &:active {
    opacity: 0.5;
    transition: none;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 170px);
  overflow-y: auto;

  h1 {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 28px;
    text-align: center;
  }
`;
