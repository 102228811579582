import { FC } from "react";
import { useRecoilValue } from "recoil";

import { localeState } from "../atom";

type I18NData = { [id: string]: string };

const DATA: { [id: string]: I18NData } = {
  en: {
    // home
    home__title: "Hospital appointment booking service",
    "home__intro-slider1-title":
      "Symptom-based multilingual smart medical inquiry",
    "home__intro-slider1-body":
      "Travelers can write their symptoms in their preferred language and have them translated into Korean to help with medical treatment. They can also choose their desired department and procedure.",
    "home__intro-slider2-title":
      "Medical staff-accompanied interpretation service",
    "home__intro-slider2-body":
      "Hitchmed's medical team provides escort, pickup, and interpretation services to help travelers feel at ease when visiting the hospital.",
    "home__intro-slider2-body-bullet1":
      "This service requires an additional payment.",
    "home__intro-slider2-body-bullet2":
      "Accompaniment by medical staff incurs additional costs.",
    "home__intro-slider2-body-bullet3":
      "Depending on the schedule, medical staff may not accompany the traveler (prior consultation required).",
    "home__button--next": "Go to book a hospital appointment",
    "home__button--next-short": "Go to book a appointment",
    precautions__title: "Precautions",

    // precautions
    precautions__subtitle:
      "If you answer 'yes' to any of the following questions, it may be difficult to make a hospital reservation.",
    "precautions__radio--no": "No",
    "precautions__radio--yes": "Yes",
    "precautions__question--1":
      "Have you been issued a foreigner registration number?",
    "precautions__question--2": "Are you enrolled in Korean health insurance?",
    "precautions__question--3":
      "Is the person who is trying to make a reservation under 14 years old?",
    "precautions__agreement--1":
      "The user is responsible for problems caused by providing incorrect information.",
    "precautions__agreement--2":
      "If you want to make a reservation for a child under 14 years old, please email us.",
    precautions__submit: "Confirm",
    "precautions__submit--disabled": "Confirm",

    // reservation step 1
    "reservation-step1__title": "Make a Medical Reservation",
    "reservation-step1__type--standard-name": "Standard",
    "reservation-step1__type--standard--feature1":
      "Hospital reservation available on desired date",
    "reservation-step1__type--standard--feature2":
      "No interpretation or companion services provided",
    "reservation-step1__type--premium-name":
      "Medical Interpretation and Companion",
    "reservation-step1__type--premium--feature1":
      "Hospital reservation available on desired date",
    "reservation-step1__type--premium--feature2":
      "Interpretation and companion services provided",
    "reservation-step1__type--premium--feature3": "Hotel pickup provided",
    "reservation-step1__type--premium--feature4":
      "1 Interpreter and 1 Professional Medical Staff as Companions",
    "reservation-step1__type--premium--feature5":
      "Basic fee $300 (based on 3 hours)",
    "reservation-step1__type--premium--feature5-2":
      "*Additional charges apply for more than 3 hours",
    "reservation-step1__submit": "Confirm",
    "reservation-step1__submit--disabled": "Confirm",

    // reservation step 2
    "reservation-step2__title": "Enter Personal Information",
    "reservation-step2__subtitle":
      "Please enter information for the person receiving treatment",
    "reservation-step2__submit": "Next",

    // reservation step 3
    "reservation-step3__title": "Enter Personal Information",
    "reservation-step3__subtitle":
      "Please enter the information of the person receiving treatment.",
    "reservation-step3__submit": "Next",

    // reservation step 4
    "reservation-step4__title": "Select Hospital Size",
    "reservation-step4__subtitle":
      "Please select the preferred size of the hospital.",
    "reservation-step4__type-hospital--name": "Hospital",
    "reservation-step4__type-hospital--feature1": "Large scale and facilities",
    "reservation-step4__type-hospital--feature2":
      "Reservation may be difficult on desired dates",
    "reservation-step4__type-hospital--feature3": "Wait times may be long",
    "reservation-step4__type-hospital--feature4":
      "Treatment costs are relatively high",
    "reservation-step4__type-clinic--name": "Clinic",
    "reservation-step4__type-clinic--feature1": "Small scale and facilities",
    "reservation-step4__type-clinic--feature2":
      "Reservations are easy to make on desired dates",
    "reservation-step4__type-clinic--feature3": "Short wait times",
    "reservation-step4__type-clinic--feature4":
      "Treatment costs are relatively low",
    "reservation-step4__type-any--name": "No preference",
    "reservation-step4__submit": "Next",
    "reservation-step4__submit--disabled": "Next",

    // reservation step 5
    "reservation-step5__title": "Select Treatment Date",
    "reservation-step5__subtitle":
      "Please select the desired date and time for the treatment.",
    "reservation-step5__submit": "Next",
    "reservation-step5__submit--disabled": "Next",

    // reservation step 6
    "reservation-step6__title": "Medical Interpretation Accompaniment Service",
    "reservation-step6__subtitle":
      "Please provide information for the Medical Interpretation Accompaniment Service.",
    "reservation-step6__submit": "Next",
    "reservation-step6__submit--disabled": "Next",

    // common
    "field__first-name": "First Name",
    "field__first-name--placeholder": "Gil-dong",
    "field__last-name": "Last Name",
    "field__last-name--placeholder": "Hong",
    field__birthday: "Date of Birth",
    "field__birthday--placeholder": "Enter 8 digits, e.g. MM/DD/YYYY",
    field__gender: "Gender",
    "field__gender--male": "Male",
    "field__gender--female": "Female",
    field__email: "Email",
    "field__email--placeholder": "Please enter your email",
    "field__email--info":
      "We will send reservation information to your email. Please enter it accurately.",
    "field__agreement-checkbox--all": "Agree to all",
    "field__agreement-checkbox--terms": "(Required) Terms of Service",
    "field__agreement-checkbox--privacy-policy":
      "(Required) Collection and Use of Personal Information Agreement",
    "field__agreement-checkbox--privacy-policy-2":
      "(Required) Agreement to Collect Personal and Sensitive Information",
    "field__agreement-checkbox--third-party":
      "(Required) Agreement to Provide Personal Information to Third Parties",
    "field__agreement-checkbox--detail": "View >",
    field__nationality: "Nationality",
    "field__nationality--info":
      "✔️ Please select the country listed in your passport",
    field__arrival: "Date of Arrival",
    "field__arrival--info":
      "✔️ This service is available only for customers who have entered Korea within the last 3 months",
    "field__arrival--placeholder": "Enter 8 digits, e.g. 2023-04-01",
    field__departure: "Date of Departure",
    "field__departure--info":
      "✔️ If your departure date is undecided, please enter your estimated departure date.",
    "field__departure--placeholder": "Enter 8 digits, e.g. 2023-04-01",
    "field__travel-insurance": "Travel Insurance",
    "field__travel-insurance--info":
      "*If you have travel insurance, select 'Insured' regardless of whether it is domestic or overseas",
    "field__travel-insurance--insured": "Insured",
    "field__travel-insurance--not-insured": "Not insured",
    "field__preferred-language": "Preferred Language",
    "field__request-language": "Requested Interpretation Language",
    "field__accompanying-persons": "Accompanying Persons",
    field__hotel: "Accommodation Hotel",
    field__symptom: "Symptom",
    "country--kr": "South Korea",
    "country--us": "United States",
    "country--jp": "Japan",
    "country--cn": "China",
    "language--ko": "Korean",
    "language--en": "English",
    "language--ja": "Japanese",
    "language--zh": "Chinese",
    "accompanying-persons--0": "None",
    "accompanying-persons--1": "1 person",
    "accompanying-persons--2": "2 peoples",
    "hotel_notice--1": "We will pick you up at the hotel lobby.",
    "hotel_notice--2": "Up to 2 accompanying persons can be picked up.",
    "hotel_notice--3":
      "We will send you an email with the exact pickup time, location, and vehicle information upon completion of your reservation.",
    "hotel_notice--4": "Interpretation is provided for a minimum of 3 hours.",
    "hotel_notice--5":
      "The Medical Interpretation Accompaniment Service starts from the pickup time, and includes waiting time at the hospital.",
    "field__reserve-at-format--am": "YYYY-MM-DD h:mm[AM]",
    "field__reserve-at-format--pm": "YYYY-MM-DD h:mm[PM]",
    "field__birthday-format": "YYYY-MM-DD",

    // ReservationDateSelector
    "reservation-calendar__today-label": "Today",
    "reservation-date-selector__month--1": "January",
    "reservation-date-selector__month--2": "February",
    "reservation-date-selector__month--3": "March",
    "reservation-date-selector__month--4": "April",
    "reservation-date-selector__month--5": "May",
    "reservation-date-selector__month--6": "June",
    "reservation-date-selector__month--7": "July",
    "reservation-date-selector__month--8": "August",
    "reservation-date-selector__month--9": "September",
    "reservation-date-selector__month--10": "October",
    "reservation-date-selector__month--11": "November",
    "reservation-date-selector__month--12": "December",
    "reservation-date-selector__note-1": "· Up to 5 selections",
    "reservation-date-selector__note-2":
      "· Select AM or PM, and we'll book the available time.",
    "reservation-date-selector__am": "AM",
    "reservation-date-selector__pm": "PM",
    "reservation-date-selector__day--mon": "Mon",
    "reservation-date-selector__day--tue": "Tue",
    "reservation-date-selector__day--wed": "Wed",
    "reservation-date-selector__day--thu": "Thu",
    "reservation-date-selector__day--fri": "Fri",
    "reservation-date-selector__day--sat": "Sat",
    "reservation-date-selector__day--sun": "Sun",
    "reservation-date-selector__date-format": "YYYY/MM/DD",
    "reservation-date-selector__full-date-format": "YYYY/MM/DD A",
    "reservation-date-selector__selected-time": "Selected Time",
    "reservation-date-selector__selected-times": "Selected Times",
    "reservation-date-selector__no-selection": "None",
    "reservation-date-selector__am-note": "AM (~12:00)",
    "reservation-date-selector__pm-note": "PM (13:00~)",

    // LanguageSelector
    "language-selector__placeholder": "Select Language",
    "language-selector__add": "Add",
    "language-selector__rank--1": "1st priority",
    "language-selector__rank--2": "2nd priority",
    "language-selector__rank--3": "3rd priority",
    "language-selector__rank--4": "4th priority",
    "language-selector__remove": "Remove",
  },
  ko: {
    // home
    home__title: "병원 예약서비스",
    "home__intro-slider1-title": "증상기반 다국어 스마트 문진제공",
    "home__intro-slider1-body":
      "여행객은 자신의 증상을 편한 언어로 작성하고 이를 한국어로 의료번역하여 진료에 도움을 줄 수 있습니다. 그 외 원하는 진료과목, 시술을 선택할 수 있습니다.",
    "home__intro-slider2-title": "의료진이 동행하는 통역서비스",
    "home__intro-slider2-body":
      "히치메드의 의료팀에서 여행객이 안심하고 병원갈 수 있도록 동행,픽업 및 통역서비스를 제공합니다.",
    "home__intro-slider2-body-bullet1":
      "해당 서비스는 추가결제가 필요한 서비스 입니다.",
    "home__intro-slider2-body-bullet2": "의료진 동행은 추가비용이 부가됩니다.",
    "home__intro-slider2-body-bullet3":
      "일정에 따라 동행하지 않을 수 있습니다.(사전논의 필요)",
    "home__button--next": "병원 예약하러가기",
    "home__button--next-short": "병원 예약하러가기",

    // precautions
    precautions__title: "주의사항",
    precautions__subtitle:
      "아래 질문에 하나라도 해당하시는 분은 병원 예약 진행이 어렵습니다.",
    "precautions__radio--no": "아니오",
    "precautions__radio--yes": "네",
    "precautions__question--1": "외국인등록번호를 발급 받았나요?",
    "precautions__question--2": "한국의 건강보험에 가입되어 있나요?",
    "precautions__question--3": "예약하려는 당사자가 14세 미만인가요?",
    "precautions__agreement--1":
      "잘못된 정보제공으로 발생하는 문제의 책임은 이용자에게 있습니다.",
    "precautions__agreement--2":
      "14세 미만 자녀 예약을 원하는 경우 메일주세요.",
    precautions__submit: "확인",
    "precautions__submit--disabled": "확인",

    // reservation step 1
    "reservation-step1__title": "진료 예약하기",
    "reservation-step1__type--standard-name": "일반",
    "reservation-step1__type--standard--feature1":
      "원하는 날짜에 병원 예약 가능",
    "reservation-step1__type--standard--feature2":
      "통역 및 동행 서비스는 제공되지 않음",
    "reservation-step1__type--premium-name": "의료통역 동행",
    "reservation-step1__type--premium--feature1":
      "원하는 날짜에 병원 예약 가능",
    "reservation-step1__type--premium--feature2": "통역 및 동행 서비스 제공",
    "reservation-step1__type--premium--feature3": "호텔 픽업 제공",
    "reservation-step1__type--premium--feature4":
      "통역1인, 전문 의료진 1인 동행",
    "reservation-step1__type--premium--feature5": "기본요금 $300(3시간 기준)",
    "reservation-step1__type--premium--feature5-2":
      "*3시간 초과시 추가비용 발생",
    "reservation-step1__submit": "확인",
    "reservation-step1__submit--disabled": "확인",

    // reservation step 2
    "reservation-step2__title": "개인정보 입력",
    "reservation-step2__subtitle": "진료받을 사람의 정보를 입력해 주세요.",
    "reservation-step2__submit": "다음",

    // reservation step 3
    "reservation-step3__title": "개인정보 입력",
    "reservation-step3__subtitle": "진료받을 사람의 정보를 입력해 주세요.",
    "reservation-step3__submit": "다음",

    // reservation step 4
    "reservation-step4__title": "병원 규모 선택",
    "reservation-step4__subtitle": "선호하는 병원의 규모를 선택해 주세요.",
    "reservation-step4__type-hospital--name": "병원",
    "reservation-step4__type-hospital--feature1": "큰 규모와 시설",
    "reservation-step4__type-hospital--feature2":
      "원하는 일자에 예약이 어려울 수 있음",
    "reservation-step4__type-hospital--feature3": "대기시간이 길어질 수 있음",
    "reservation-step4__type-hospital--feature4": "진료비가 비싼편",
    "reservation-step4__type-clinic--name": "의원",
    "reservation-step4__type-clinic--feature1": "작은 규모와 시설",
    "reservation-step4__type-clinic--feature2": "원하는 일자에 예약이 쉬운편",
    "reservation-step4__type-clinic--feature3": "대기시간이 길지않음",
    "reservation-step4__type-clinic--feature4": "진료비가 저렴한편",
    "reservation-step4__type-any--name": "상관없음",
    "reservation-step4__submit": "다음",
    "reservation-step4__submit--disabled": "다음",

    // reservation step 5
    "reservation-step5__title": "진료 날짜 선택",
    "reservation-step5__subtitle":
      "진료를 원하는 날짜와 시간대를 전부 선택해 주세요.",
    "reservation-step5__submit": "다음",
    "reservation-step5__submit--disabled": "다음",

    // reservation step 6
    "reservation-step6__title": "의료 통역 동행 서비스",
    "reservation-step6__subtitle":
      "의료 통역 동행 서비스를 위한 정보를 입력해 주세요.",
    "reservation-step6__submit": "다음",
    "reservation-step6__submit--disabled": "다음",

    // reservation step 7
    "reservation-step7__title": "증상입력",
    "reservation-step7__subtitle": "증상이나 병원방문 이유를 입력해 주세요.",
    "reservation-step7__submit": "다음",
    "reservation-step7__submit--disabled": "다음",
    "reservation-step7__comment--1": "증상을 자세히 입력해 주세요",
    "reservation-step7__comment--2":
      "증상이 없다면 내원하는 이유나 원하는 진료과를 적어주세요",

    // reservation step 8
    "reservation-step8__subtitle1": "문진을 진행해 주세요.",
    "reservation-step8__subtitle2":
      "작성하신 내용을 토대로 알맞은 병원을 예약해 드립니다.",
    "reservation-step8__comment":
      "해당 문진은 환자로부터 이전의 병력에 대한 기초 정보로 활용되며, 실제 병원 진료 시 차이가 발생할 수 있습니다.",
    "reservation-step8__submit": "문진 시작하기",
    "reservation-step8__notice--title": "안내사항",
    "reservation-step8__notice--body": `해당 문진은 비 의료행위로서, 환자로부터 현재 복용 중인 의약품이나 이전 병력 등 진료에 필요한 사전 기초 정보를 수집하고, 필요한 경우 번역 절차만을 거친 후 의료기관에 단순 전달하기 위한 수단으로만 활용됩니다. 따라서 실제 의료기관에서 진료 시 차이가 발생할 수 있으며, 문진 결과에 근거한 의료행위와 관련된 모든 책임은 의료행위를 한 의료인 및 의료기관에 있습니다.\n\n자세한 문진 내용은 의료인 및 의료기관에 한번 더 설명해주시길 바랍니다.`,
    "reservation-step8__notice--confirm": "확인",

    // reservation step 9
    "reservation-step9__title": "공통문진",
    "reservation-step9__subtitle": "임신여부를 선택해주세요.",
    "reservation-step9__submit": "다음",
    "reservation-step9__submit--disabled": "다음",
    "reservation-step9__skip": "질문 건너뛰기>",

    // reservation step 10
    "reservation-step10__title": "공통문진",
    "reservation-step10__subtitle": "해당되는 과거력을 선택해주세요.",
    "reservation-step10__subtitle2": "* 중복선택 가능",
    "reservation-step10__submit": "다음",
    "reservation-step10__submit--disabled": "다음",
    "reservation-step10__skip": "질문 건너뛰기>",

    // reservation step 11
    "reservation-step11__title": "공통문진",
    "reservation-step11__subtitle": "해당되는 과거력을 선택해주세요.",
    "reservation-step11__subtitle2": "* 중복선택 가능",
    "reservation-step11__submit": "다음",
    "reservation-step11__submit--disabled": "다음",
    "reservation-step11__skip": "질문 건너뛰기>",

    // reservation step 12
    "reservation-step12__title": "특이사항 입력",
    "reservation-step12__subtitle":
      "의사 또는 매니저에게 하고 싶은  말이 있나요?",
    "reservation-step12__submit": "다음",
    "reservation-step12__submit--disabled": "다음",
    "reservation-step12__comment--1":
      "의료진이 알아야 하는 내용을 입력해 주세요.",
    "reservation-step12__comment--2":
      "예약 시 고려해야 하는 내용을 입력해 주세요.",
    "reservation-step12__comment--3":
      "진료 시 고려해야 하는 내용을 입력해 주세요.",

    // reservation detail
    // --requested
    "reservation-detail__title--requested": "예약신청 완료",
    "reservation-detail__subtitle--requested": "예약해 주셔서 감사합니다.",
    "reservation-detail__comment1--requested":
      "예약은 작성하신 이메일로 안내드립니다!",
    "reservation-detail__comment2--requested":
      "혹시 이메일이 오지 않으신다면 스팸함을 확인해 주세요",
    "reservation-detail__comment3--requested":
      "문의사항은 medical-concierge@hitchmed.com로 메일을 보내주세요",

    "reservation-detail__id": "예약번호",
    "reservation-detail__copy-button": "복사",
    "reservation-detail__user-info": "사용자 정보",

    // common
    field__name: "이름/성",
    "field__first-name": "이름",
    "field__first-name--placeholder": "길동",
    "field__last-name": "성",
    "field__last-name--placeholder": "홍",
    field__birthday: "생년월일",
    "field__birthday--placeholder": "8자리 입력 ex)1990-12-25",
    field__gender: "성별",
    "field__gender--male": "남",
    "field__gender--female": "여",
    field__email: "이메일",
    "field__email--placeholder": "이메일을 입력해주세요",
    "field__email--info":
      "Email로 예약 정보를 알려드립니다. 정확히 입력해주세요.",
    "field__agreement-checkbox--all": "약관 전체동의",
    "field__agreement-checkbox--terms": "(필수) 서비스이용약관 ",
    "field__agreement-checkbox--privacy-policy":
      "(필수) 개인정보수집 및 이용동의",
    "field__agreement-checkbox--privacy-policy-2":
      "(필수) 개인정보 및 민감정보 수집동의",
    "field__agreement-checkbox--third-party":
      "(필수) 개인정보 제 3자 제공 동의 ",
    "field__agreement-checkbox--detail": "보기>",
    field__nationality: "국적",
    "field__nationality--info": "✔️ 여권에 기재된 국가를 선택해주세요",
    field__arrival: "입국일",
    "field__arrival--info":
      "✔️ 해당 서비스는 한국에 입국한지 3개월이 되지않은 고객만이용이 가능합니다",
    "field__arrival--placeholder": "8자리 입력 ex)2023-04-01",
    field__departure: "출국예정일",
    "field__departure--info":
      "✔️ 출국일이 미정인 경우에는 예상 출국일을 입력해주세요.",
    "field__departure--placeholder": "8자리 입력 ex)2023-04-01",
    "field__travel-insurance": "여행자보험 가입여부",
    "field__travel-insurance--info":
      "*국내외 상관 없이 여행자 보험에 가입하셨다면 ‘가입’ 선택",
    "field__travel-insurance--insured": "가입",
    "field__travel-insurance--not-insured": "가입하지 않음",
    "field__preferred-language": "사용언어",
    "field__request-language": "통역 제공언어",
    "field__accompanying-persons": "동반인원",
    field__hotel: "숙박호텔",
    field__symptom: "증상",
    "field__symptom--placeholder": "증상을 입력해 주세요.\nex) 복통 및 설사",
    "field__to-doctor--placeholder":
      "특이사항을 입력해 주세요.\nex) 아이가 큰 소리를 무서워 합니다.",
    "field__pregnant--pregnant": "임신중",
    "field__pregnant--not-pregnant": "비임신",
    "field__pregnant--not-sure": "모름",
    "field__medical_history--page-1-no-history-1": "특별히 앓고있는 질환 없음",
    "field__medical_history--page-1-hypertension": "고혈압",
    "field__medical_history--page-1-diabetes": "당뇨병",
    "field__medical_history--page-1-hyperlipidemia": "고지혈증",
    "field__medical_history--page-1-hepatitis-b": "B형간염",
    "field__medical_history--page-1-manual-history": "기타과거력",
    "field__medical_history--page-2-medical-check-up": "최근에 건강검진을 받음",
    "field__medical_history--page-2-allergy":
      "알레르기가 있는 약물 또는 물질이 있음",
    "field__medical_history--page-2-surgery": "과거에 수술받은적 있음",
    "field__medical_history--page-2-radiation": "방사선 치료를 받은적 있음",
    "field__medical_history--page-2-no-history-2": "해당되는 사항없음",
    "field__medical_history--others-comment--placeholder":
      "과거력을 직적 입력해주세요.",
    "field__medical_history--checkup-in-month--placeholder": "",
    "field__medical_history--allergy-comment--placeholder":
      "알레르기 유발물질을 직접 입력해주세요.",
    "field__medical_history--surgery-in-month--placeholder": "",
    "field__medical_history--radiation-in-month--placeholder": "",
    "field__medical_history--in-month-suffix": "개월전",
    "country--kr": "한국",
    "country--us": "미국",
    "country--jp": "일본",
    "country--cn": "중국",
    "language--ko": "한국어",
    "language--en": "영어",
    "language--ja": "일본어",
    "language--zh": "중국어",
    "accompanying-persons--0": "없음",
    "accompanying-persons--1": "1명",
    "accompanying-persons--2": "2명",
    "hotel_notice--1": "호텔의 로비에서 픽업해 드립니다.",
    "hotel_notice--2": "동반인원은 최대 2명까지 픽업이 가능합니다",
    "hotel_notice--3":
      "정확한 픽업시간과 픽업장소 및 차량은 예약완료 메일로 전달드립니다.",
    "hotel_notice--4": "통역은 기본 3시간 제공됩니다.",
    "hotel_notice--5":
      "의료 통역 동행 서비스는 픽업 시간부터 시작되며, 병원 대기시간도포함됩니다.",
    "type__hospital--name": "병원",
    "type__clinic--name": "의원",
    "type__any--name": "상관없음",
    field__pregnancy: "임신여부",
    "field__past-medical-history": "과거질환력",
    "field__surgical-history": "수술력",
    "field__health-checkup-status": "건강검진여부",
    "field__radiation-therapy-history": "방사선치료력",
    "field__medication-usage": "약물 복용",
    "field__allergy-history": "알레르기",
    "field__available-treatment-days": "진료가능일",
    "field__desired-hospital-size": "희망병원규모",
    "field__special-notes": "특이사항",
    "field__interpretation-language": "통역언어",
    "field__major-symptoms": "주요증상",
    "field__treatment-requests": "진료 희망사항",
    "field__medical-interpretation-escort-service": "의료통역 동행서비스",
    "field__common-medical-examination-content": "공통문진 내용",
    "field__notice-and-refund-guide": `안내사항\n • 기본 $300로 3시간 제공됩니다.\n • 3시간 초과 시 시간 당 $100가 추가로 부과됩니다.\n • 픽업 시간부터 샌딩 시간까지의 시간을 책정합니다.\n • 병원의 대기시간도 포함됩니다.\n\n환불정책\n • 진료 당일 취소 : 환불 불가능\n • 진료 1일 전 취소 : 50% 환불\n • 진료 2일 이전 취소 : 100% 환불`,
    "field__hospital-type-hospital--name": "병원",
    "field__hospital-type-clinic--name": "의원",
    "field__hospital-type-any--name": "상관없음",
    "field__reserve-at-format": "YYYY-MM-DD A h:mm",
    "field__reserve-at-format--am": "YYYY-MM-DD [오전] h:mm",
    "field__reserve-at-format--pm": "YYYY-MM-DD [오후] h:mm",
    "field__birthday-format": "YYYY-MM-DD",

    // ReservationDateSelector
    "reservation-calendar__today-label": "오늘",
    "reservation-date-selector__month--1": "1월",
    "reservation-date-selector__month--2": "2월",
    "reservation-date-selector__month--3": "3월",
    "reservation-date-selector__month--4": "4월",
    "reservation-date-selector__month--5": "5월",
    "reservation-date-selector__month--6": "6월",
    "reservation-date-selector__month--7": "7월",
    "reservation-date-selector__month--8": "8월",
    "reservation-date-selector__month--9": "9월",
    "reservation-date-selector__month--10": "10월",
    "reservation-date-selector__month--11": "11월",
    "reservation-date-selector__month--12": "12월",
    "reservation-date-selector__note-1": "· 최대 5개 선택가능",
    "reservation-date-selector__note-2":
      "· 오전, 오후만 선택하시면 가능한 시간으로 예약해 드립니다.",
    "reservation-date-selector__am": "오전",
    "reservation-date-selector__pm": "오후",
    "reservation-date-selector__day--mon": "월",
    "reservation-date-selector__day--tue": "화",
    "reservation-date-selector__day--wed": "수",
    "reservation-date-selector__day--thu": "목",
    "reservation-date-selector__day--fri": "금",
    "reservation-date-selector__day--sat": "토",
    "reservation-date-selector__day--sun": "일",
    "reservation-date-selector__date-format": "YYYY[년] MM[월] DD[일]",
    "reservation-date-selector__full-date-format": "YYYY[년] MM[월] DD[일] A",
    "reservation-date-selector__selected-time": "선택시간",
    "reservation-date-selector__selected-times": "선택시간",
    "reservation-date-selector__no-selection": "없음",
    "reservation-date-selector__am-note": "오전 (~12:00)",
    "reservation-date-selector__pm-note": "오후 (13:00~)",

    // LanguageSelector
    "language-selector__placeholder": "언어선택",
    "language-selector__add": "추가",
    "language-selector__rank--1": "1순위",
    "language-selector__rank--2": "2순위",
    "language-selector__rank--3": "3순위",
    "language-selector__rank--4": "4순위",
    "language-selector__remove": "삭제",
  },
  ja: {
    // home
    home__title: "病院予約サービス",
    "home__intro-slider1-title": "症状に基づく多言語スマート診療提供",
    "home__intro-slider1-body":
      "旅行者は自分の症状を好きな言語で記述し、それを韓国語に医療翻訳して治療に役立てることができます。また、希望する診療科目や処置を選択することもできます。",
    "home__intro-slider2-title": "医療スタッフが同行する通訳サービス",
    "home__intro-slider2-body":
      "ヒッチメッドの医療チームは、旅行者が安心して病院に行けるように同行、ピックアップ、通訳サービスを提供しています。",
    "home__intro-slider2-body-bullet1": "このサービスは追加料金が必要です。",
    "home__intro-slider2-body-bullet2":
      "医療スタッフの同行は追加費用がかかります。",
    "home__intro-slider2-body-bullet3":
      "スケジュールによっては、医療スタッフが同行しない場合があります（事前相談が必要です）。",
    "home__button--next": "病院の予約に行く",
    "home__button--next-short": "病院の予約に行く",
    precautions__title: "注意事項",

    // precautions
    precautions__subtitle:
      "下記の質問に当てはまる方は、病院の予約手続きが困難です。",
    "precautions__radio--no": "いいえ",
    "precautions__radio--yes": "はい",
    "precautions__question--1": "外国人登録番号を発行されたことはありますか？",
    "precautions__question--2": "韓国の健康保険に加入されていますか？",
    "precautions__question--3": "予約する本人が14歳未満ですか？",
    "precautions__agreement--1":
      "誤った情報提供によって発生する問題の責任は利用者にあります。",
    "precautions__agreement--2":
      "14歳未満のお子様の予約をご希望の場合は、メールにてお問い合わせください。",
    precautions__submit: "確認",
    "precautions__submit--disabled": "確認",

    // reservation step 1
    "reservation-step1__title": "医療予約",
    "reservation-step1__type--standard-name": "一般",
    "reservation-step1__type--standard--feature1": "希望日に病院予約可能",
    "reservation-step1__type--standard--feature2":
      "通訳および同行サービスは提供されていません",
    "reservation-step1__type--premium-name": "医療通訳および同行",
    "reservation-step1__type--premium--feature1": "希望日に病院予約可能",
    "reservation-step1__type--premium--feature2":
      "通訳および同行サービスを提供",
    "reservation-step1__type--premium--feature3": "ホテルピックアップを提供",
    "reservation-step1__type--premium--feature4":
      "通訳1名、専門医療スタッフ1名の同行",
    "reservation-step1__type--premium--feature5": "基本料金 $300（3時間基準）",
    "reservation-step1__type--premium--feature5-2":
      "*3時間を超える場合、追加料金が発生します",
    "reservation-step1__submit": "確認",
    "reservation-step1__submit--disabled": "確認",

    // reservation step 2
    "reservation-step2__title": "個人情報入力",
    "reservation-step2__subtitle": "受診者情報を入力してください",
    "reservation-step2__submit": "次へ",

    // reservation step 3
    "reservation-step3__title": "個人情報入力",
    "reservation-step3__subtitle": "受診される方の情報を入力してください。",
    "reservation-step3__submit": "次へ",

    // reservation step 4
    "reservation-step4__title": "病院の規模を選択",
    "reservation-step4__subtitle": "ご希望の病院の規模を選択してください。",
    "reservation-step4__type-hospital--name": "病院",
    "reservation-step4__type-hospital--feature1": "大規模で充実した施設",
    "reservation-step4__type-hospital--feature2":
      "希望の日程に予約が取りにくい場合があります。",
    "reservation-step4__type-hospital--feature3":
      "待ち時間が長くなる場合があります。",
    "reservation-step4__type-hospital--feature4": "診療費が高めです。",
    "reservation-step4__type-clinic--name": "診療所",
    "reservation-step4__type-clinic--feature1": "小規模で簡易的な施設",
    "reservation-step4__type-clinic--feature2":
      "希望の日程に予約しやすい場合があります。",
    "reservation-step4__type-clinic--feature3":
      "待ち時間が短い場合があります。",
    "reservation-step4__type-clinic--feature4": "診療費が安めです。",
    "reservation-step4__type-any--name": "どちら",
    "reservation-step4__submit": "次へ",
    "reservation-step4__submit--disabled": "次へ",

    // reservation step 5
    "reservation-step5__title": "診療日選択",
    "reservation-step5__subtitle":
      "受診される方のご希望の診療日と時間帯をすべて選択してください。",
    "reservation-step5__submit": "次へ",
    "reservation-step5__submit--disabled": "次へ",

    // reservation step 6
    "reservation-step6__title": "医療通訳同行サービス",
    "reservation-step6__subtitle":
      "医療通訳同行サービスの情報を提供してください。",
    "reservation-step6__submit": "次へ",
    "reservation-step6__submit--disabled": "次へ",

    // common
    "field__first-name": "名前",
    "field__first-name--placeholder": "ギルドン",
    "field__last-name": "性",
    "field__last-name--placeholder": "ホン",
    field__birthday: "生年月日",
    "field__birthday--placeholder":
      "8桁の数字を入力してください。例)1990-12-25",
    field__gender: "性別",
    "field__gender--male": "男",
    "field__gender--female": "女",
    field__email: "メールアドレス",
    "field__email--placeholder": "メールアドレスを入力してください",
    "field__email--info":
      "予約情報をメールでお知らせします。正確に入力してください。",
    "field__agreement-checkbox--all": "全てに同意する",
    "field__agreement-checkbox--terms": "（必須）利用規約",
    "field__agreement-checkbox--privacy-policy":
      "（必須）個人情報の収集および利用に同意する",
    "field__agreement-checkbox--privacy-policy-2":
      "（必須）個人情報および機密情報の収集に同意する",
    "field__agreement-checkbox--third-party":
      "（必須）第三者への個人情報提供に同意する",
    "field__agreement-checkbox--detail": "詳細を見る>",
    field__nationality: "国籍",
    "field__nationality--info":
      "✔️ パスポートに記載されている国を選択してください",
    field__arrival: "入国日",
    "field__arrival--info":
      "✔️ 当サービスは、韓国に入国してから3ヶ月以内のお客様のみが利用可能です",
    "field__arrival--placeholder": "8桁入力してください ex)2023-04-01",
    field__departure: "出国日",
    "field__departure--info":
      "✔️ 出国日が未定の場合は、出国予定日を入力してください。",
    "field__departure--placeholder": "8桁入力してください ex)2023-04-01",
    "field__travel-insurance": "旅行保険に加入していますか？",
    "field__travel-insurance--info":
      "※国内外問わず、旅行保険に加入している場合は、必ず「加入」を選択してください",
    "field__travel-insurance--insured": "加入",
    "field__travel-insurance--not-insured": "未加入",
    "field__preferred-language": "希望言語",
    "field__request-language": "通訳提供言語",
    "field__accompanying-persons": "同行人数",
    field__hotel: "宿泊ホテル",
    "country--kr": "韓国",
    "country--us": "アメリカ合衆国",
    "country--jp": "日本",
    "country--cn": "中国",
    "language--ko": "韓国語",
    "language--en": "英語",
    "language--ja": "日本語",
    "language--zh": "中国語",
    "accompanying-persons--0": "なし",
    "accompanying-persons--1": "1名",
    "accompanying-persons--2": "2名",
    "hotel_notice--1": "ホテルのロビーでお迎えいたします。",
    "hotel_notice--2": "最大2名まで同行できます。",
    "hotel_notice--3":
      "予約完了後、正確なお迎えの時間・場所・車両情報をメールにてお送りいたします。",
    "hotel_notice--4": "通訳サービスは最低でも3時間提供されます。",
    "hotel_notice--5":
      "医療通訳同行サービスはお迎えの時間から開始し、病院の待ち時間も含まれます。",
    "field__reserve-at-format--am": "MM/DD/YYYY h:mmA",
    "field__reserve-at-format--pm": "MM/DD/YYYY h:mmA",
    "field__birthday-format": "MM/DD/YYYY",

    // ReservationDateSelector
    "reservation-calendar__today-label": "今日",
    "reservation-date-selector__month--1": "1月",
    "reservation-date-selector__month--2": "2月",
    "reservation-date-selector__month--3": "3月",
    "reservation-date-selector__month--4": "4月",
    "reservation-date-selector__month--5": "5月",
    "reservation-date-selector__month--6": "6月",
    "reservation-date-selector__month--7": "7月",
    "reservation-date-selector__month--8": "8月",
    "reservation-date-selector__month--9": "9月",
    "reservation-date-selector__month--10": "10月",
    "reservation-date-selector__month--11": "11月",
    "reservation-date-selector__month--12": "12月",
    "reservation-date-selector__note-1": "・最大5つ選択可能",
    "reservation-date-selector__note-2":
      "・午前か午後を選択するだけで、可能な時間で予約いたします。",
    "reservation-date-selector__am": "午前",
    "reservation-date-selector__pm": "午後",
    "reservation-date-selector__day--mon": "月",
    "reservation-date-selector__day--tue": "火",
    "reservation-date-selector__day--wed": "水",
    "reservation-date-selector__day--thu": "木",
    "reservation-date-selector__day--fri": "金",
    "reservation-date-selector__day--sat": "土",
    "reservation-date-selector__day--sun": "日",
    "reservation-date-selector__date-format": "YYYY年MM月DD日",
    "reservation-date-selector__full-date-format": "YYYY年MM月DD日 A",
    "reservation-date-selector__selected-time": "選択した時間",
    "reservation-date-selector__selected-times": "選択した時間",
    "reservation-date-selector__no-selection": "なし",
    "reservation-date-selector__am-note": "午前 (~12:00)",
    "reservation-date-selector__pm-note": "午後 (13:00~)",

    // LanguageSelector
    "language-selector__placeholder": "言語を選択",
    "language-selector__add": "追加",
    "language-selector__rank--1": "第一優先",
    "language-selector__rank--2": "第二優先",
    "language-selector__rank--3": "第三優先",
    "language-selector__rank--4": "第四優先",
    "language-selector__remove": "削除",
  },
  zh: {
    // home
    home__title: "医院预约服务",
    "home__intro-slider1-title": "基于症状的多语言智能诊疗提供",
    "home__intro-slider1-body":
      "旅行者可以用自己喜欢的语言描述症状，并将其翻译成韩语以帮助治疗。此外，还可以选择所需的诊疗科目和手术。",
    "home__intro-slider2-title": "医护人员陪同的翻译服务",
    "home__intro-slider2-body":
      "Hitchmed的医疗团队为旅行者提供陪同、接送和翻译服务，以便他们安心前往医院。",
    "home__intro-slider2-body-bullet1": "此服务需要额外付款。",
    "home__intro-slider2-body-bullet2": "医护人员陪同需要支付额外费用。",
    "home__intro-slider2-body-bullet3":
      "根据日程安排，医护人员可能无法陪同（需要事先咨询）。",
    "home__button--next": "去预约医院",
    "home__button--next-short": "去预约医院",
    precautions__title: "预防措施",

    // precautions
    precautions__subtitle:
      "如果下面的问题中有一个或多个适用于您，请注意，您可能无法进行医院预约。",
    "precautions__radio--no": "否",
    "precautions__radio--yes": "是",
    "precautions__question--1": "您是否已获得外国人注册号码？",
    "precautions__question--2": "您是否已加入韩国的医疗保险？",
    "precautions__question--3": "预订的当事人是否未满14岁？",
    "precautions__agreement--1": "由于提供错误信息而导致的问题由用户自负。",
    "precautions__agreement--2":
      "如果您想预订14岁以下的儿童，请通过电子邮件联系我们。",
    precautions__submit: "确认",
    "precautions__submit--disabled": "确认",

    // reservation step 1
    "reservation-step1__title": "医疗预约",
    "reservation-step1__type--standard-name": "普通",
    "reservation-step1__type--standard--feature1": "可在所需日期预约医院",
    "reservation-step1__type--standard--feature2": "不提供口译或陪同服务",
    "reservation-step1__type--premium-name": "医疗口译和陪同",
    "reservation-step1__type--premium--feature1": "可在所需日期预约医院",
    "reservation-step1__type--premium--feature2": "提供口译和陪同服务",
    "reservation-step1__type--premium--feature3": "提供酒店接送",
    "reservation-step1__type--premium--feature4":
      "提供1名口译员和1名专业医护人员陪同",
    "reservation-step1__type--premium--feature5":
      "基本费用 $300（以3小时为基准）",
    "reservation-step1__type--premium--feature5-2": "*超过3小时将产生额外费用",
    "reservation-step1__submit": "确认",
    "reservation-step1__submit--disabled": "确认",

    // reservation step 2
    "reservation-step2__title": "输入个人信息",
    "reservation-step2__subtitle": "请输入接受治疗者的信息",
    "reservation-step2__submit": "下一步",

    // reservation step 3
    "reservation-step3__title": "输入个人信息",
    "reservation-step3__subtitle": "请输入接受治疗的人的信息。",
    "reservation-step3__submit": "下一步",

    // reservation step 4
    "reservation-step4__title": "选择医院规模",
    "reservation-step4__subtitle": "请选择偏好的医院规模。",
    "reservation-step4__type-hospital--name": "医院",
    "reservation-step4__type-hospital--feature1": "大型和设施齐全",
    "reservation-step4__type-hospital--feature2": "可能难以在所需日期预订",
    "reservation-step4__type-hospital--feature3": "等待时间可能很长",
    "reservation-step4__type-hospital--feature4": "治疗费用相对较高",
    "reservation-step4__type-clinic--name": "诊所",
    "reservation-step4__type-clinic--feature1": "小型和设施简单",
    "reservation-step4__type-clinic--feature2": "所需日期预订容易",
    "reservation-step4__type-clinic--feature3": "等待时间不长",
    "reservation-step4__type-clinic--feature4": "治疗费用相对较低",
    "reservation-step4__type-any--name": "不限",
    "reservation-step4__submit": "下一步",
    "reservation-step4__submit--disabled": "下一步",

    // reservation step 5
    "reservation-step5__title": "选择就诊日期",
    "reservation-step5__subtitle": "请选择想要的就诊日期和时间段。",
    "reservation-step5__submit": "下一步",
    "reservation-step5__submit--disabled": "下一步",

    // reservation step 6
    "reservation-step6__title": "医疗翻译陪同服务",
    "reservation-step6__subtitle": "请提供医疗翻译陪同服务的信息。",
    "reservation-step6__submit": "下一步",
    "reservation-step6__submit--disabled": "下一步",

    // common
    "field__first-name": "名字",
    "field__first-name--placeholder": "吉尔东",
    "field__last-name": "姓",
    "field__last-name--placeholder": "洪",
    field__birthday: "出生日期",
    "field__birthday--placeholder": "输入8位数字，例如MM/DD/YYYY",
    field__gender: "性别",
    "field__gender--male": "男",
    "field__gender--female": "女",
    field__email: "电子邮件",
    "field__email--placeholder": "请输入您的电子邮件",
    "field__email--info": "我们将向您的电子邮件发送预约信息。请准确输入。",
    "field__agreement-checkbox--all": "同意全部",
    "field__agreement-checkbox--terms": "（必填）服务条款",
    "field__agreement-checkbox--privacy-policy":
      "（必填）同意收集和使用个人信息",
    "field__agreement-checkbox--privacy-policy-2":
      "（必填）同意收集个人和敏感信息",
    "field__agreement-checkbox--third-party":
      "（必填）同意向第三方提供个人信息",
    "field__agreement-checkbox--detail": "查看详情>",
    field__nationality: "国籍",
    "field__nationality--info": "✔️ 请选择护照上所列国家",
    field__arrival: "入境日期",
    "field__arrival--info": "✔️ 该服务仅适用于在过去三个月内进入韩国的客户",
    "field__arrival--placeholder": "输入8位数，例如2023-04-01",
    field__departure: "出境日期",
    "field__departure--info": "✔️ 如果您的出境日期未定，请输入预计出境日期。",
    "field__departure--placeholder": "输入8位数，例如2023-04-01",
    "field__travel-insurance": "旅游保险购买情况",
    "field__travel-insurance--info":
      "※无论是国内还是海外旅游保险，请选择“购买”",
    "field__travel-insurance--insured": "购买",
    "field__travel-insurance--not-insured": "未购买",
    "field__preferred-language": "首选语言",
    "field__request-language": "提供口译语言",
    "field__accompanying-persons": "同行人数",
    field__hotel: "住宿酒店",
    "country--kr": "韩国",
    "country--us": "美国",
    "country--jp": "日本",
    "country--cn": "中国",
    "language--ko": "韩语",
    "language--en": "英语",
    "language--ja": "日语",
    "language--zh": "中文",
    "accompanying-persons--0": "无",
    "accompanying-persons--1": "1人",
    "accompanying-persons--2": "2人",
    "hotel_notice--1": "我们将在酒店大堂接您。",
    "hotel_notice--2": "最多可接待两名陪同人员。",
    "hotel_notice--3":
      "预订完成后，我们将通过电子邮件发送准确的接送时间、地点和车辆信息。",
    "hotel_notice--4": "翻译服务最少提供3小时。",
    "hotel_notice--5": "医疗翻译陪同服务从接送时间开始计算，包括医院等待时间。",
    "field__reserve-at-format--am": "YYYY-MM-DD [AM] h:mm",
    "field__reserve-at-format--pm": "YYYY-MM-DD [PM] h:mm",
    "field__birthday-format": "YYYY-MM-DD",

    // ReservationDateSelector
    "reservation-calendar__today-label": "今天",
    "reservation-date-selector__month--1": "1月",
    "reservation-date-selector__month--2": "2月",
    "reservation-date-selector__month--3": "3月",
    "reservation-date-selector__month--4": "4月",
    "reservation-date-selector__month--5": "5月",
    "reservation-date-selector__month--6": "6月",
    "reservation-date-selector__month--7": "7月",
    "reservation-date-selector__month--8": "8月",
    "reservation-date-selector__month--9": "9月",
    "reservation-date-selector__month--10": "10月",
    "reservation-date-selector__month--11": "11月",
    "reservation-date-selector__month--12": "12月",
    "reservation-date-selector__note-1": "· 最多可选择5个",
    "reservation-date-selector__note-2":
      "· 只需选择上午或下午，我们将为您预订可用的时间。",
    "reservation-date-selector__am": "上午",
    "reservation-date-selector__pm": "下午",
    "reservation-date-selector__day--mon": "周一",
    "reservation-date-selector__day--tue": "周二",
    "reservation-date-selector__day--wed": "周三",
    "reservation-date-selector__day--thu": "周四",
    "reservation-date-selector__day--fri": "周五",
    "reservation-date-selector__day--sat": "周六",
    "reservation-date-selector__day--sun": "周日",
    "reservation-date-selector__date-format": "YYYY年MM月DD日",
    "reservation-date-selector__full-date-format": "YYYY年MM月DD日 A",
    "reservation-date-selector__selected-time": "选择的时间",
    "reservation-date-selector__selected-times": "选择的时间",
    "reservation-date-selector__no-selection": "无选择",
    "reservation-date-selector__am-note": "上午 (~12:00)",
    "reservation-date-selector__pm-note": "下午 (13:00~)",

    // LanguageSelector
    "language-selector__placeholder": "选择语言",
    "language-selector__add": "添加",
    "language-selector__rank--1": "第1希望",
    "language-selector__rank--2": "第2希望",
    "language-selector__rank--3": "第3希望",
    "language-selector__rank--4": "第4希望",
    "language-selector__remove": "删除",
  },
};

export const useI18N = (name: string) => {
  const locale = useRecoilValue(localeState);

  return DATA[locale][name] ?? name;
};

export const I18N: FC<{ name: string }> = ({ name }) => {
  const locale = useRecoilValue(localeState);

  return <>{DATA[locale][name] || name}</>;
};
