import { forwardRef } from "react";
import styled from "styled-components";

import iconRight from "./right.svg";

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  buttonStyle?: "select" | "main";
}

export const Button = forwardRef<HTMLButtonElement, Props>(
  ({ buttonStyle, ...props }, ref) => {
    return <MButton buttonStyle={buttonStyle} ref={ref} {...props} />;
  }
);
Button.displayName = "Button";

const MButton = styled.button<{ buttonStyle?: Props["buttonStyle"] }>`
  width: 100%;
  border-radius: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 30px;
  transition: 400ms background, 400ms border, 400ms opacity;

  & + & {
    margin-top: 6px;
  }

  &:active {
    opacity: 0.5;
    transition: none;
  }

  ${({ buttonStyle, disabled }) => {
    switch (buttonStyle) {
      case "select":
        return `
          background: none;
          border: 1px #DDE0E7 solid;
          color: #585F6B;
          font-weight: 300;
          text-align: left;
          padding: 12px 14px;
          display: flex;
          min-height: 48px;
          font-size: 16px;
          transition: 400ms background, 400ms border;
    
          &:active {
            opacity: 1;
            transition: none;
            border: 1px #0762C8 solid;
            background: #0762C812;
            color: #0762C8;
          }
        
          &:disabled {
            border: 1px #0762C8 solid;
            background: #0762C812;
            color: #0762C8;
            font-weight: normal;
            pointer-events: none;
          }
      `;
      case "main":
        return `
          font-size: 18px;
          background: #0762c8;
          min-height: 56px;
          border: none;
          color: #ffffff;
          background-image: url(${iconRight});
          background-repeat: no-repeat;
          background-position: right 16px center;
        `;
      default:
        return `
          font-size: 16px;
          background: #0762c8;
          color: #ffffff;
          border: none;
          min-height: 48px;
          font-weight: bold;

          ${
            disabled &&
            `
              background: #D9DBE1;
              color: #9C9FA5;
              pointer-events: none;
            `
          }
        `;
    }
  }}
`;
